import { getLeadId, loadScript } from "../utils"
import { DFI } from "../../tienda"
import { global } from "../../globalvars_Config/globalvars"

export const loadConversion = function (idleadParams, fn) {
  const idlead = idleadParams || DFI.getLeadId() || getLeadId()
  
  loadScript(global.tagConversion[DFI.getClient()] + idlead)
    .then(() => {
      if (fn) {
        fn()
      }
    })
}
