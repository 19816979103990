import { waitForAnalyticsObjects } from "./existAnalytics"

export async function loadEspt(adformUid){
    const Libraries=await waitForAnalyticsObjects()
    if(Libraries){
      globalThis.DAL.Ajax({
        type: "jsonp",
        url: "https://t.womtp.com/epst",
        data: {
          idsite: "5f28300c4f97c65b1c0e82e1974f35f2",
          idvisitor: globalThis.Piwik.getAsyncTracker().getVisitorId(),
          adfid: adformUid,
          v: "1",
        },
      }).done(function () {
         console.info("Espt carga con exito")
      }).fail(function () {
        console.error("Fallo en la llamada a Espst")
      })
    }
  
}