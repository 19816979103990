export const getLeadId = function (winScope) {
  winScope = winScope || window

  if (globalThis.DFI.leadId) {
    return globalThis.DFI.leadId
  }

  if (globalThis.DELIO.leadId) {
    return globalThis.DFI.leadId
  }

  if (winScope.Piwik) {
    return winScope.Piwik.getAsyncTracker().getVisitorId()
  }

  return ""
}
