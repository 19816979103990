class ErrorWalmeric extends Error {
    constructor(mensaje,tipoError,fichero,linea) {
      super(mensaje);
      this.name = this.constructor.name;
      this.mensaje=mensaje
      this.tipoError = tipoError;
      this.fichero = fichero;
      this.linea=linea
      this.fechaError=new Date()
      if (Error.captureStackTrace) {
        Error.captureStackTrace(this, this.constructor);
      } else {
        this.stack = (new Error()).stack;
      }
    }
  }

  export default ErrorWalmeric