// @ts-nocheck
export const addListenerInputsNumber = function () {
  const inputsWal = document.querySelectorAll("form.wg-form input[type='number'].wg-input")

  inputsWal.forEach( input => {
    input.type = "tel"
    input.addEventListener("input", function (objEvt) {
      if (input.value.length == 1) {
        var cont = input.value;
        if (objEvt.data) {
          if (
            objEvt.data == "6" ||
            objEvt.data == "7" ||
            objEvt.data == "8" ||
            objEvt.data == "9"
          ) {
          } else {
            input.value = cont.substr(0, cont.length - 1);
          }
        }
      } else if (input.value.length <= 9) {
        var cont = input.value;
        if (objEvt.data) {
          if (objEvt.data.match("[0-9]")) {
          } else {
            input.value = cont.substr(0, cont.length - 1);
          }
        }
      }
    });
  });
};