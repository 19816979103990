export const addTrackingProcess = function () {
  const piwikDefined = setInterval(function () {
    if (
      typeof globalThis.Piwik === "object" &&
      typeof globalThis.Piwik.getAsyncTracker() === "object" &&
      typeof globalThis.Piwik.getAsyncTracker().getVisitorId() === "string"
    ) {
      // @ts-ignore
      clearInterval(piwikDefined);

      const urlsToInterfere = document.getElementsByTagName("a");
      const leadIdTracking = globalThis.Piwik.getAsyncTracker().getVisitorId();

      if (
        leadIdTracking &&
        typeof leadIdTracking != undefined &&
        urlsToInterfere &&
        typeof urlsToInterfere != undefined
      ) {
        for (let urlsIndex = 0, len = urlsToInterfere.length;
          urlsIndex < len;
          urlsIndex++
        ) {
          if (
            urlsToInterfere[urlsIndex].href.indexOf(
              "/tienda-online/particulares/contratacion"
            ) > 0 &&
            !urlsToInterfere[urlsIndex].href.includes("dlidl") &&
            leadIdTracking.length > 20
          ) {
            urlsToInterfere[urlsIndex].href += "&dlidl=" + leadIdTracking;
          }
        }
      }
    }
  }, 100);
};