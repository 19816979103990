import { loadMcvid } from "../functionsPlatform/loadMcvid";
import { adobe_teallium } from "../functionsPlatform/adobe_teallium";
import { getDataLeadRingpool } from "../functionsTracking/functionsRingpool";
import { fakeDatalayer } from "../funtionsRefactor/getConsentValues";
import { velocityInternet } from "../funtionsRefactor/velocityInternet";



/**
 * Funcion que se encarga de adquirir los valores de consent del dataLayer 
 * y devuelve una promesa.
 * @param {*} dataLayer 
 * @returns getConsentValues
 */
const getConsentValues = (dataLayer) => {
  if(globalThis.location.host==="localhost:9006"){
     dataLayer=fakeDatalayer
  }
 return new Promise((resolve, reject) => {
    let counter = 0;
    const MAX_COUNTER = 20;
    let lastConsentValues = null;

    
    const checkConsentValues = () => {
      counter++;
      try {
        if (typeof dataLayer === 'undefined') {
          // En preproduccion es normal ya que no viene un dataLayer
          reject(new Error('ERROR : Datalayer no esta definido'));
          return;
        }
        
        for (let i = dataLayer.length - 1; i >= 0; i--) {
          const item = dataLayer[i];
          if (item[0] === "consent") {
             const consentValues = {
              ad_storage: item[2].ad_storage ?? "",
              analytics_storage: item[2].analytics_storage ?? "",
              ad_personalization: item[2].ad_personalization ?? "",
              ad_user_data: item[2].ad_user_data ?? "",
            };
            lastConsentValues = consentValues;
            resolve(lastConsentValues);
            return;
          }
        }
        
        if (counter > MAX_COUNTER) {
          reject(new Error("Error: Maximum number of attempts for ConsentMode exceeded."));
          return;
        }
        
        // Check again after 1000ms
        setTimeout(checkConsentValues, 700);
      } catch (error) {
        reject(error);
      }
    };
    
    // Start the initial check
    setTimeout(()=>{checkConsentValues()},1000);
  });
};


async function conexionLow(name) {
  return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
          let nameEQ = name + "=";
          let ca = document.cookie.split(';');
          for (let i = 0; i < ca.length; i++) {
              let c = ca[i].trim();
              if (c.indexOf(nameEQ) === 0) {
                  // Usa decodeURIComponent para decodificar el valor de la cookie
                  let valor = decodeURIComponent(c.substring(nameEQ.length, c.length));
                  if (valor) {
                      clearInterval(interval); // Detener el intervalo
                      resolve(valor); // Resolver la promesa con el valor de la cookie
                      break; // Salir del bucle for
                  }
              }
          }
      }, 1000);
  });
}


async function getCookieValue(name) {
  const conexion = await velocityInternet();
  if(conexion<=2){
    const valor = await conexionLow(name);
    return valor;
  }else{
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i].trim();
        if (c.indexOf(nameEQ) === 0) {
            // Usa decodeURIComponent para decodificar el valor de la cookie
            return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
    }
    return null;
  }
  
}


async function explodeParamCookie(nameCookie, paramBuscar) {
  let queryString = await getCookieValue(nameCookie);
  if (!queryString) {
    return null; // La cookie no tiene valor o no existe
  }

  // Dividir la cadena de consulta en pares clave-valor
  let params = queryString.split('&');
  let paramValue = null;

  try {
    // Buscar el parámetro y obtener sus valores si existe
    for (let i = 0; i < params.length; i++) {
      let [key, value] = params[i].split('=');
      if (key === paramBuscar) {
        paramValue = value;
        break;
      }
    }

    // Si paramValue se encontró, procesar el valor
    if (paramValue !== null) {
      let paramArray = paramValue.split(','); // Separa por comas para obtener cada par id:valor
      let paramPairs = paramArray.map((pair) => {
        let [id, value] = pair.split(':'); // Separa cada par por el dos puntos para obtener id y valor
        return { id, value }; // Retorna un objeto con id y valor
      });
      return paramPairs;
    }
  } catch (error) {
    console.error('Ocurrió un error al procesar el parámetro:', error);
  }
  return null;
}

const dataConsent = async () => {
  const consent = await getConsentValues(globalThis.dataLayer);
  // Asigna un objeto vacío si `consent` es falso
  globalThis.consentValues = consent || {};
};


export async function watchCookie(name, paramBuscar, adformUid,callback) {
  let lastCookieValue = explodeParamCookie(name, paramBuscar);
  let timeoutId = null; // ID del timeout para poder cancelarlo más tarde
  const startTime = Date.now(); // Tiempo de inicio para calcular el tiempo transcurrido
  const maxDuration = 3 * 60 * 1000; // Tiempo para parar el observador

  const checkCookieChange = async () => {

    // Verifica si han pasado X minutos desde que comenzó la observación
    if (Date.now() - startTime >= maxDuration) {
      console.log(`Se ha excedido el tiempo máximo de observación de la cookie "${name}".`);
      return; // Detiene la función si han pasado 5 minutos
    }

    let currentCookieValue = await explodeParamCookie(name, paramBuscar);

    // let bttTeLlamamos = document.getElementsByClassName("ctc-form-banner")[0];
    // if(currentCookieValue && bttTeLlamamos instanceof HTMLInputElement){
    //   bttTeLlamamos.disabled=false;
    //   bttTeLlamamos.value="Te llamamos gratis";
    // }

    // Verificar si la condición específica se cumple para detener la observación
    let stopConditionMet = currentCookieValue && currentCookieValue.some(pair => pair.id === '1' && pair.value === '1') && currentCookieValue.some(pair => pair.id === '2' && pair.value === '1');
    if (stopConditionMet) {
      console.log(`Usuario acepto la cookie "${name}" tiene el valor específico; deteniendo la observación y cargando el mcvid`);
      // Cargar subTags
      adobe_teallium()
      
      //Introducir datos(de cookie) consent
      await dataConsent();
      /*Cargamos el MCVID*/
      loadMcvid();

       /*Actualizamos valores del ringpool*/
       const adForm_uuid=adformUid
       let cookieAnaliticaInformada=true
       let dataLeadRp=await getDataLeadRingpool(adForm_uuid,cookieAnaliticaInformada)
       globalThis.DRP.loadLabels(dataLeadRp)
    } else {
      timeoutId = setTimeout(async function (){
        if (JSON.stringify(currentCookieValue) !== JSON.stringify(lastCookieValue)) {
          //callback(currentCookieValue);
          lastCookieValue = currentCookieValue;

          if (!stopConditionMet) {
            checkCookieChange(); // Continuar observando
          } else {
            console.log(`Usuario acepto la cookie "${name}" tiene el valor específico; deteniendo la observación y cargando el mcvid`);
            // Cargar subTags
            adobe_teallium()
            
            //Introducir datos(de cookie) consent
            await dataConsent();

            /*Cargamos el MCVID*/
            loadMcvid();

          
            /*Actualizamos valores del ringpool*/
            const adForm_uuid=adformUid
            let cookieAnaliticaInformada=true
            let dataLeadRp=await getDataLeadRingpool(adForm_uuid,cookieAnaliticaInformada)
            globalThis.DRP.loadLabels(dataLeadRp)
       
          }
        } else {
          checkCookieChange(); // Vuelve a chequear después de un tiempo
        }
      }, 1000); // Intervalo de sondeo
    }
  };

  // Iniciar la observación de la cookie
  checkCookieChange();

  // Establecer un límite de tiempo para detener la observación después de 5 minutos
  setTimeout(() => {
    clearTimeout(timeoutId);
    console.log(`Se ha detenido la observación de la cookie "${name}" después de 5 minutos.`);
  }, maxDuration);
}