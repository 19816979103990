export const getDevice = ()=>{
   
        const agenteUsuario = navigator.userAgent.toLowerCase();

        const tiposDispositivos = {
            'android': 'Phone/ S.O Android.',
            'iphone|ipad|ipod': '(iPhone, iPad, iPod)/ S.O IOS.',
            'windows': 'Desktop/Windows.',
            'macintosh': 'Macintosh (Mac)/S.O MAC OS.',
            'linux': 'Desktop /S.O Linux.'
        };

        for (const dispositivo in tiposDispositivos) {
            const patronRegex = new RegExp(dispositivo, 'i');
            if (patronRegex.test(agenteUsuario)) {
                return tiposDispositivos[dispositivo];
            }
        }

        return 'Unknow';
    
};