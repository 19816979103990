// Configuracion cliente
export const clientConfig = {
  timeoutTooltip: 3,
  lang: 'es',
  pos: 'bottom',
  sendHidden: true,
  type: 'element',
  validate: true,
  validation: {
    notification: {
      type: 'element',
    },
  },
}

// Objeto de configuracion variables Globales
export const global = {
  // AMP config
  amp: {
    check: false,
    url: 'https://t.womtp.com/slider/c/vodafone/amp-clientid/index.php',
    cookie: 'womtp_a2bc',
  },

  loadTagVisit: true,

  /**
   * @see https://walmeric.atlassian.net/wiki/spaces/WFRONT/pages/639860740/Delio+Front+Tools+-+ndice
   */
  dal: 'https://ws.walmeric.com/static/dft/dist/v1/dal.js',
  drp: 'https://ws.walmeric.com/static/dft/dist/v2/drp.js',
  drpTest:'https://ws.walmeric.com/static/dft/integration/v3/drp.js',
  dmv: 'https://ws.walmeric.com/static/dft/dist/v1/dmv.js',
  dul: 'https://ws.walmeric.com/static/dft/dist/v1/dul.js',
  dcl: 'https://ws.walmeric.com/static/dft/dist/v2/dcl.js?idTag=29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a5fc1ca581ceb7bf8fd143a36f4eb693794c0f95c76664a12dbaa532a82b5988a207dd2d28aa08a82723b60cd54ef0e48b3a24e4b4ad1c4968e7803e10bb5ed21b0441305a1103889a0fa0536abc02d8c',
  dsa: 'https://ws.walmeric.com/static/dft/dist/v1/dsa.js',
  dvl: 'https://ws.walmeric.com/static/dft/dist/v2/dvl.js',
  det: 'https://ws.walmeric.com/static/dft/dist/v1/det.js',
  lht: 'https://t.womtp.com/slider/c/vodafone/lht/init.js',

  // Delio Scripts
  tagVisit: {
    ono: 'https://t.womtp.com/js/otg.min.js?idtag=e8700339672a24e39f5bb7719466f497',
    // vodafone: enpoint Perteneciente a tag Visita Produccio
    //'https://t.womtp.com/js/otg.min.js?idtag=2a246268b4561c54e468f77255d7d0c6',
    //Vodafone tag vita Pre: NO USAR EN PRO 
    //https://t.womtp.com/js/otg.min.js?5f28300c4f97c65b1c0e82e1974f35f2
    vodafone:'https://t.womtp.com/js/otg.min.js?idtag=5f28300c4f97c65b1c0e82e1974f35f2',
    vodafone_pruebas:
      'https://t.womtp.com/js/otg.min.js?idtag=11e6d96474f13c544e2630c5dcdb2147',
    yu: 'https://t.womtp.com/js/otg.min.js?idtag=7037b791a18b3ecc7603299bc770575f',
  },

  tagConversion: {
    vodafone:
      'https://t.womtp.com/js/otg.min.js?idtag=a4e1d940effa825b51a1032a3c3c6ce8&orderid=',
    vodafone_pruebas:
      'https://t.womtp.com/js/otg.min.js?idtag=11e6d96474f13c544e2630c5dcdb2147&orderid=',
    yu: 'https://t.womtp.com/js/otg.min.js?idtag=a894b640dfd8cbd7d7dfe2aabd8608c8&orderid=',
  },
  tagDelioClient:
    'https://ws.walmeric.com/static/dft/dist/v2/dcl.js?idTag=29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a5fc1ca581ceb7bf8fd143a36f4eb693794c0f95c76664a12dbaa532a82b5988a207dd2d28aa08a82723b60cd54ef0e48b3a24e4b4ad1c4968e7803e10bb5ed21b0441305a1103889a0fa0536abc02d8c',
  tagDelioClient_Inactividad:
    'https://ws.walmeric.com/static/js/dev/v1/dcl.js?idTag=29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a5fc1ca581ceb7bf8fd143a36f4eb693794c0f95c76664a12dbaa532a82b5988a83ee4186391092587d0a63186160a89442e6230812dd23968409e5c2fe991f39882575b0217ccba96b569760cf761f17',
  tagDelioClient_tienda_movil:
    'https://ws.walmeric.com/static/js/dev/v1/dcl.js?idTag=29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a5fc1ca581ceb7bf8fd143a36f4eb693794c0f95c76664a12dbaa532a82b5988aceff058bb88f0bbf5683554a447b645842e2c7b2e006af7cd8088f262221a4d04dfe05deae20aed84ce3101ac310cfa3',
  // Ids
  idTagDelioClient:
    '29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a5fc1ca581ceb7bf8fd143a36f4eb693794c0f95c76664a12dbaa532a82b5988a207dd2d28aa08a82723b60cd54ef0e48b3a24e4b4ad1c4968e7803e10bb5ed21b0441305a1103889a0fa0536abc02d8c',
  idTagDelioClient_Cobertura:
    '29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a5fc1ca581ceb7bf8fd143a36f4eb693794c0f95c76664a12dbaa532a82b5988a2e19411824665dc92070e1a4b3d0ac182f31f4b4396e694dec2745190cfd69e761131ac1d5d19b0188a6e1b626367f3a',
  idTagDelioClient_tienda_movil:
    '29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a5fc1ca581ceb7bf8fd143a36f4eb693794c0f95c76664a12dbaa532a82b5988aceff058bb88f0bbf5683554a447b645842e2c7b2e006af7cd8088f262221a4d04dfe05deae20aed84ce3101ac310cfa3',
  idTagDelioClient_Rastreator:
    '29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a5fc1ca581ceb7bf8fd143a36f4eb693794c0f95c76664a12dbaa532a82b5988a0c8dad0add050bb70301454f17bc5b5d542c8b963d7906f012d23f3533ebc60def6b762ac4188723fb4ae3038f3c0ed0',
  idTagWhatsapp:
    '29842f94d414949bf95fb2e6109142cfef1fb2a78114c2c536a36bf5a65b953a5fc1ca581ceb7bf8fd143a36f4eb693794c0f95c76664a12dbaa532a82b5988aceff058bb88f0bbf5683554a447b6458fc786c3cd237fecff53bf6dbdd841191d16171f6c3a85488fae8bb0d53bdc16c',

  // IdServicio RingPool
  rpLanding: 'c8d85c6aebf14547c575dfccac639730',

  // Paths Support
  pathSupport: 'https://t.womtp.com/slider/d/vodafone/e2e/vodafone_partis/dist/support/',
  // Paths Root por ahora es d
  pathRoot: 'https://t.womtp.com/slider/d/vodafone/e2e/',
  // Paths Root por ahora es d
  pathRootCss:
    'https://t.womtp.com/slider/d/vodafone/e2e/vodafone_partis/dist/',

  // AddLead
  addLead: 'https://ws.walmeric.com/provision/wsclient/client_addlead.html',
  sendLabelRingpool:'https://ws.walmeric.com/provision/wsclient/sendLabelsRingPool.html?format=jsonp&',
  putLead:
    'https://ws.walmeric.com/provision/wsclient/delioClientLeadId_put.html',
  putUid: 'https://ws.walmeric.com/provision/wsclient/delioClientUid_put.html',
  // ISP - ApiGurus
  apiISP:
    'https://https-api.apigurus.com/iplocation/v1.8/locateip?key=SAK89R7297783RD3R63Z&ip=local&format=JSON',
}


export const rpServices = {
  landing_fcbk: 'c8d85c6aebf14547c575dfccac639730',
  landing_afil: '434519db9c2e2eb33e113328e8a60a37',
  landing_amazon: '15611b9fe96a9a18c62b8d9e58dce6a6',
  ono_afiliacion: '6a05b33f6fcf85254de6fdabb64986d7',
  ono_cartera_m: 'ea51a656e3facb617784bae94314a079', // medio
  ono_cartera_a: '0cd7184d1b3f6d1e477325d5677b5dbf', // alto
  ono_potencial_a: 'e3128c90c6d190c300af6c0b6f6401ed', // medio
  ono_potencial_m: '38296c91c96e860178d1b740baf93417', // alto
  landing_sem_m: '85171502622e4bb26c7c51b8d5cbdc55', // alto
  landing_sem_a: '74c8588ed41d6de8204123c87b56a541', // medio
  landing_agora: 'CC2478E71B08018C990E544B5B403304',
}

export const trackingParams = [
  'cid',
  'did',
  'emd',
  'sms',
  'afp',
  'afe',
  'afp',
  'smp',
  'smi',
  'ned',
  'con',
  'wta',
  'vfp',
  'vfe',
  'vfa',
  'mca',
]
