import { getUrlNotifyD3P } from "./getUrlNotifyD3P"

export const notifyD3P = function (data) {
  console.log("D3P Notify")

  const urlNotify = getUrlNotifyD3P(data)

  if (!urlNotify) {
    return false
  }

  const iframe = document.createElement("iframe")
  iframe.src = urlNotify
  iframe.id = "D3P-Notify"
  iframe.style.display = "none"

  document.body.appendChild(iframe)

  return iframe
}
