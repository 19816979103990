/**
 * Sustituya a loadScriptAsync o loadScript
 * @param {String} url La url de la librería a cargar
 * @returns Una promesa de cada carga de la librería
 */
export function loadLib(url) {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    script.onload = () => {
      // Dejamos 1000 ms para cargar en JS
      setTimeout(() => {
        // @ts-ignore
        clearInterval(interval);
        resolve();
      }, 1000);
    };
    script.onerror = () => {
      // @ts-ignore
      clearInterval(interval);
      reject();
    };
    document.head.appendChild(script);

    const interval = setInterval(() => {
      // Comprobar si la promesa aún no se ha resuelto ni rechazado
      if (script.onload === null && script.onerror === null) {
        // @ts-ignore
        clearInterval(interval);
        reject(new Error('Tiempo de espera agotado'));
      }
    }, 100);
  });
}