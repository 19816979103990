import { getLeadId } from "../utils"
import { getUrl } from "./getUrl"

export const getUrlNotifyD3P = function (data) {
  if (!(globalThis.D3P && globalThis.D3P.notify && globalThis.D3P.notify.ws)) {
    return false
  }

  const leadId = data.leadId || getLeadId(),
    url = getUrl(true),
    reason = data.message || "",
    reg = data.result || "",
    cliente = globalThis.D3P.notify.cliente || "",
    type = data.type || "callme"

  let urlNotify =
    globalThis.D3P.notify.ws +
    "?leadId=" +
    leadId +
    "&url=" +
    url +
    "&registration=" +
    reg +
    "&cliente=" +
    cliente +
    "&reason=" +
    reason +
    "&type=" +
    type

  if (globalThis.D3P.notify.params) {
    let params = globalThis.D3P.notify.params
    params = params.replace("{leadId}", leadId)
    params = params.replace("{type}", type)
    params = params.replace("{reason}", reason)
    params = params.replace("{registration}", reg)

    if (params[0] !== "&") {
      params = "&" + params
    }

    urlNotify = urlNotify + params
  }

  return urlNotify
}
