import { addFormField } from "./addFormField"

export const addField_declarativaUrl = function (decValue, decvalue2) {

  const input = document.createElement("input")

  input.type = "hidden"
  input.name = "TipoProducto"
  input.value = decValue

  addFormField(input)

  const input2 = document.createElement("input")
  
  input2.type = "hidden"
  input2.name = "TipoTienda"
  input2.value = decvalue2

  addFormField(input2)
}
