import { DFI } from "../../tienda";


/**
 * Funcion encargada de añadir el evento responsable de recoger el valor de la localizacion
 * del calme, la cual se encuetra en el atributo data-analytics-location del calme.
 */
export const getLocationCtaCallmeRefactor = () => {

  const botones_configuradores = [
    {
      url: "ALL",
      id: "wg-submit",
    },
    {
      url: "https://www.vodafone.es/c/particulares/es/",
      id: "wal_rpmodal-location",
    },
    {
      url: "https://www.vodafone.es/c/particulares/es/",
      id: "wal_cmmodal-location",
    },
  ];

  botones_configuradores.forEach((elem) => {
    const elementExist = document.querySelectorAll("." + elem.id);
    if(elementExist != null && typeof elementExist != "undefined"){
      elementExist.forEach((element) => 
        element.addEventListener('mousedown', function(){
          const locationElement= element.getAttribute("data-analytics-location")!=" " ? element.getAttribute("data-analytics-location"): "NO TIENE UN LOCATION CTA ASOCIADO";
          const valueLocation=globalThis.location_cta_personalizacion!=" " ? globalThis.location_cta_personalizacion :locationElement;
          globalThis.location_cta =valueLocation
          globalThis.element_cta=locationElement
        })
      )
    }
  })
    
};