export const getInterfaceMode = function () {

  const landing = {
    host: [
      "smartphonesvodafone.com",
      "tarifas.com",
      "precio.com",
      "multioferta.es",
      "www.vodafone.es"
    ],
    paths: [
      "/tienda/particulares/es/informacion/promocion-prueba/",
      "/tienda/particulares/es/productos/samsung-galaxy-note9/",
      "/tienda/particulares/es/productos/iphone-xs/",
      "/tienda/particulares/es/productos/iphone-9/",
      "/tienda/particulares/es/productos/iphone-xs-plus/",
      "/tienda/particulares/es/productos/nuevo-iphone/",
      "/tienda/particulares/es/productos/nuevos-iphone/",
      "/tienda/particulares/es/productos/iphone-xs-max/",
      "/tienda/particulares/es/productos/iphone-xc/",
      "/tienda/particulares/es/productos/apple-watch/",
      "/tienda/particulares/es/productos/prueba-uno/",
      "/static/microsites/particulares-landing-iphone-xs/index.html",
      "/static/microsites/particulares-prueba-uno/index.html",
      "/static/microsites/particulares-apple-watch/index.html",
      "/static/microsites/particulares-prueba-oferta/index.html",
      "/tienda/particulares/es/productos/samsung-galaxy-s10",
      "/c/particulares/es/productos-y-servicios/movil/catalogo-clientes/",
      "/c/particulares/es/productos-y-servicios/movil/catalogo-clientes/v2",
      "/static/microsites/descuentos-clientes-1/index.html",
      "/static/microsites/descuentos-clientes-2/index.html",
      "/static/microsites/smartphones-clientes-3/index.html",
      "/static/microsites/smartphones-clientes-2/index.html",
      "/static/microsites/smartphones-clientes-1/index.html",
      "/c/particulares/es/productos-y-servicios/soy-cliente/",
      "/c/particulares/es/tienda/ofertas-y-promociones/one-ilimitada-super/",
      "/yuPruebas/",
      "/c/particulares/es/yu/social-pass-gratis/",
      "/c/tienda-online/particulares/tarifas-moviles/",
      "/c/prueba-performance-fbra-yu/",
      "/c/particulares/es/yu/fibra-yu/",
      "/c/particulares/es/productos-y-servicios/moviles/samsung-galaxy-note-10/",
      "/c/particulares/es/productos-y-servicios/movil/tarifas-sin-permanencia/",
      "/c/particulares/es/productos-y-servicios/movil/tarifas-oferta/",
      "/c/particulares/es/productos-y-servicios/moviles/iphone-11/",
      "/c/particulares/es/productos-y-servicios/moviles/iphone-11-pro-max/",
      "/c/particulares/es/productos-y-servicios/moviles/iphone-11/prueba/",
      "/c/oferta/particulares/fibra100mb/",
      "/c/oferta/particulares/fibra600mb/",
      "/c/oferta/particulares/one-mini/",
      "/c/oferta/particulares/one-extra/",
      "/c/oferta/particulares/one-ilimitada/",
      "/c/oferta/particulares/one-ilimitada-super/",
      "/c/oferta/particulares/one-ilimitada-total/",
      "/c/oferta/particulares/mini/",
      "/c/oferta/particulares/extra/",
      "/c/oferta/particulares/ilimitada/",
      "/c/oferta/particulares/ilimitada-super/",
      "/c/oferta/particulares/ilimitada-total/",
      "/c/oferta/particulares/tv-packs/",
      "/c/particulares/es/productos-y-servicios/television/paquete-tv-6/",
      "/c/particulares/es/productos-y-servicios/television/paquete-tv-7/",
      "/c/particulares/es/productos-y-servicios/movil/gamers",
      "/c/particulares/es/productos-y-servicios/informacion/navidad/",
      "/c/prueba-vodafone-pass-formulario/",
      "/c/particulares/es/productos-y-servicios/movil/vodafone-pass/",
      "/c/particulares/es/productos-y-servicios/moviles/samsung-s20/",
      "/c/particulares/es/productos-y-servicios/moviles/samsung-galaxy-z-flip/",
      "/c/particulares/es/productos-y-servicios/moviles/samsung-galaxy-note-20/",
      "/c/particulares/es/productos-y-servicios/moviles/samsung-galaxy-s20-fe-5g/",
      "/c/particulares/es/productos-y-servicios/ofertas-clientes-vodafone/",
      "/static/microsites/particulares-samsung-note9/",
      "/static/microsites/particulares-fibra-yuser-clientes/",
      "/static/microsites/particulares-fibra-yuser-hazte-cliente/",
      "/static/microsites/particulares-vodafone-fibra/",
      "/static/microsites/particulares-pasate-contrato-yu/index.html",
      "/c/particulares/es/productos-y-servicios/television/hbo-espana/",
      "/c/particulares/es/mas-con-vodafone/objetos-conectados/v-home/amazon-echo/",
      "/c/particulares/es/mas-con-vodafone/objetos-conectados/",
      "/c/particulares/es/tienda/ofertas-y-promociones/amazon-prime/",
      "/c/particulares/es/productos-y-servicios/moviles/samsung-galaxy-s10/",
      "/c/particulares/es/productos-y-servicios/movil/catalogo-clientes/v2/",
      "/c/tienda-online/particulares/catalogo-moviles/",
      "/c/particulares/es/oferta/one-vodafone/",
      "/c/particulares/es/productos-y-servicios/moviles/apple-iphone-se-2020/",
      "/c/particulares/es/productos-y-servicios/movil/contrato/tarifas-contrato/tarifa-4/",
      "/c/particulares/es/productos-y-servicios/vodafone-one",
      "/c/tienda/tol/ftol/static/html/formularioffee.html",
      "/c/pruebas/",
      "/c/particulares/es/productos-y-servicios/moviles/samsung-s21",
      "/c/tienda-online/particulares/"
    ]
  }

  const ono = [
    "www.ono.es",
    "62.42.232.239",
    "62.42.232.238",
    "172.21.4.28"
  ]

  if (landing.paths.includes(location.pathname) || landing.host.includes(location.hostname)) {
    return "landing";
  }

  if (ono.includes(location.hostname)) {
    return "ono"
  }

  if (
    location.hostname === "www.vodafone.es" &&
    (window.location.href.indexOf("static/microsites/test-fibrafijo/index.html") ||
      (window.location.href.indexOf(
        "tienda/particulares/es/internet-y-fijo/fibra-ono-fijo"
      ) &&
        globalThis.targetTestVersion &&
        globalThis.targetTestVersion.FY1718_T025 === "alternativa"))
  ) {
    return "one-click"
  }
  
  if (
    (location.hostname === "www.vodafone.es" &&
      window.location.pathname.indexOf("vodafone-one/vf-rec")) ||
    (location.hostname === "www.vodafone.es" &&
      window.location.pathname.indexOf(
        "vodafone-one/configurador-recomendador-paquete-tarifas-oferta-one/"
      ))
  ) {
    return "recom"
  }

  if (
    location.hostname === "www.vodafone.es" &&
    window.location.pathname.indexOf(
      "/tienda/particulares/es/one/configurar-oferta/"
    )
  ) {
    return "configurador"
  }

  if (
    location.hostname === "www.vodafone.es" ||
    location.hostname === "www.vodafoneyu.es" ||
    location.hostname === "www.yu.vodafone.es"
  ) {
    return ""
  }

  return "landing"
}
