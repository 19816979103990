// Funcion para medir la velocidad del internet
export const velocityInternet = async ()=>{
    // @ts-ignore
    var conexion = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
    if (conexion && conexion.downlink) {
        let velocidad = conexion.downlink;
        // Aquí puedes definir la velocidad para considerar una conexión a 3G o menor
        return velocidad ? velocidad : "Velocidad de internet desconocida."
    }

    }
