export const isComprafacilInteface = function () {
  const urls = [
    "www.vodafone.es/tienda/particulares/es/productos/samsung-galaxy-note9/",
    "www.vodafone.es/tienda/particulares/es/informacion/promocion-prueba/",
    "www.vodafone.es/tienda/particulares/es/one-todo-en-uno/fibra-movil-tv/",
    "www.vodafone.es/tienda/particulares/es/one-todo-en-uno/fibra-movil/",
    "www.vodafone.es/tienda/particulares/es/one-todo-en-uno/contratar-television/",
    "www.vodafone.es/tienda/particulares/es/one-todo-en-uno/television-con-one/",
    "www.vodafone.es/tienda/particulares/es/movil/solo-sim/",
    "www.vodafone.es/tienda/particulares/es/movil/ya-soy-cliente/",
    "www.vodafone.es/tienda/particulares/es/movil/no-soy-cliente/",
    "www.vodafone.es/tienda/particulares/es/productos/hbo-con-vodafone/",
    "www.vodafone.es/tienda/particulares/es/one/todo-el-futbol/",
    "www.vodafone.es/tienda/particulares/es/internet-y-fijo/internet-movil/",
    "www.vodafone.es/tienda/particulares/es/internet-y-fijo/fibra-fijo/",
    "www.vodafone.es/tienda/particulares/es/catalogo/ficha/particular/movil/samsung/galaxy-s9-azul/",
    "www.vodafone.es/tienda/particulares/es/clientes/catalogo-moviles/contrato/",
    "www.vodafone.es/tienda/particulares/es/catalogo-moviles/pasate-a-contrato/",
    "www.vodafone.es/tienda/particulares/es/catalogo/ficha/prepago/movil/tarjeta/sim_vodafone_internacional_smartphone/",
    "www.vodafone.es/tienda/particulares/es/catalogo/ficha/prepago/movil/tarjeta/sim_yuser/",
    "www.vodafone.es/tienda/particulares/es/catalogo/ficha/prepago/movil/tarjeta/sim_vodafone_internacional_voz/",
    "www.vodafone.es/tienda/particulares/es/catalogo/ficha/prepago/movil/tarjeta/vodafone_fcil/",
    "www.vodafone.es/tienda/particulares/es/catalogo/ficha/particular/movil/vodafone/r218/",
    "www.vodafone.es/tienda/particulares/es/catalogo/ficha/particular/movil/vodafone/smart_tab_n8/",
    "www.vodafone.es/tienda/particulares/es/catalogo/ficha/particular/movil/vodafone-en-tu-casa/adoc-k1/", // "vodafoneyu.es",
    "www.vodafone.es/tienda/particulares/es/catalogo/ficha/prepago/movil/tarjeta/sim_yuser/",
    "www.vodafone.es/tienda/particulares/es/catalogo/ficha/prepago/movil/tarjeta/sim_superyuser/",
    "www.vodafone.es/tienda/particulares/es/one/configurar-oferta/",
    "www.vodafone.es/tienda/particulares/es/informacion/ventajas-comprar-online/", //WCS-3863 - Compra Fácil > Nueva URL > Estrena Smartphone
    "www.vodafone.es/tienda/particulares/es/productos/promocion-smartphone/", //AACF-130 - Indra nos pide que carguemos compra fácil en esas url
    "10.225.122.241:22080/tienda/particulares/es/catalogo/ficha/particular",
    "10.225.122.241:22080/tienda/autonomos/es/me/catalogo/ficha/microempresa",
    "10.225.239.197:10080/tienda/particulares/es/catalogo/ficha/particular",
    "10.225.239.197:10080/tienda/autonomos/es/me/catalogo/ficha/microempresa", //AACF-134 veo que cuando lo pones en catalan se suprime el /ca, asique lo he puesto de su forma y de la que nos funciona por si lo cambian en un futuro
    "www.vodafone.es./ca/tienda/particulares/es/one-todo-en-uno/fibra-movil-tv/",
    "www.vodafone.es./ca/tienda/particulares/es/one-todo-en-uno/fibra-movil",
    "www.vodafone.es./ca/tienda/particulares/es/one-todo-en-uno/contratar-television/",
    "www.vodafone.es./ca/tienda/particulares/es/one-todo-en-uno/television-con-one/?mostrarGE=true",
    "www.vodafone.es./ca/tienda/particulares/es/movil/solo-sim/",
    "www.vodafone.es./ca/tienda/particulares/es/movil/ya-soy-cliente/",
    "www.vodafone.es./ca/tienda/particulares/es/movil/no-soy-cliente/",
    "www.vodafone.es./ca/tienda/particulares/es/productos/hbo-con-vodafone/",
    "www.vodafone.es./ca/tienda/particulares/es/one/todo-el-futbol/",
    "www.vodafone.es./ca/tienda/particulares/es/internet-y-fijo/fibra-fijo/",
    "www.vodafone.es./ca/tienda/particulares/es/informacion/ventajas-comprar-online/",
    "www.vodafone.es./ca/tienda/particulares/es/one/configurar-oferta/",
    "www.vodafone.es./tienda/particulares/es/one-todo-en-uno/fibra-movil-tv/",
    "www.vodafone.es./tienda/particulares/es/one-todo-en-uno/fibra-movil",
    "www.vodafone.es./tienda/particulares/es/one-todo-en-uno/contratar-television/",
    "www.vodafone.es./tienda/particulares/es/one-todo-en-uno/television-con-one/?mostrarGE=true",
    "www.vodafone.es./tienda/particulares/es/one-todo-en-uno/television-con-one?mostrarGE=true",
    "www.vodafone.es./tienda/particulares/es/movil/solo-sim/",
    "www.vodafone.es./tienda/particulares/es/movil/ya-soy-cliente/",
    "www.vodafone.es./tienda/particulares/es/movil/no-soy-cliente/",
    "www.vodafone.es./tienda/particulares/es/productos/hbo-con-vodafone/",
    "www.vodafone.es./tienda/particulares/es/one/todo-el-futbol/",
    "www.vodafone.es./tienda/particulares/es/internet-y-fijo/fibra-fijo/",
    "www.vodafone.es./tienda/particulares/es/informacion/ventajas-comprar-online/",
    "www.vodafone.es./tienda/particulares/es/one/configurar-oferta/",
    "www.vodafone.es/ca/tienda/particulares/es/one-todo-en-uno/fibra-movil-tv/",
    "www.vodafone.es/ca/tienda/particulares/es/one-todo-en-uno/fibra-movil",
    "www.vodafone.es/ca/tienda/particulares/es/one-todo-en-uno/contratar-television/",
    "www.vodafone.es/ca/tienda/particulares/es/one-todo-en-uno/television-con-one/?mostrarGE=true",
    "www.vodafone.es/ca/tienda/particulares/es/one-todo-en-uno/television-con-one?mostrarGE=true",
    "www.vodafone.es/ca/tienda/particulares/es/movil/solo-sim/",
    "www.vodafone.es/ca/tienda/particulares/es/movil/ya-soy-cliente/",
    "www.vodafone.es/ca/tienda/particulares/es/movil/no-soy-cliente/",
    "www.vodafone.es/ca/tienda/particulares/es/productos/hbo-con-vodafone/",
    "www.vodafone.es/ca/tienda/particulares/es/one/todo-el-futbol/",
    "www.vodafone.es/ca/tienda/particulares/es/internet-y-fijo/fibra-fijo/",
    "www.vodafone.es/ca/tienda/particulares/es/informacion/ventajas-comprar-online/",
    "www.vodafone.es/ca/tienda/particulares/es/one/configurar-oferta/", //WCS-4399 240918
    "www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one-resideno/", // WCS-4509 - Formulario Compra Fácil > Nuevas urls
    "www.vodafone.es/static/microsites/particulares-tarifas-test/index.html",
    "www.vodafone.es/static/microsites/particulares-fibra-fijo-test-v2/index.html",
    "www.vodafone.es/tienda/particulares/es/catalogo-moviles/",
    "www.vodafone.es/tienda/particulares/es/catalogo-moviles/prepago/",
    "www.vodafone.es/tienda/particulares/es/catalogo-tablets/",
    "www.vodafone.es/tienda/particulares/es/catalogo-modems/contrato/",
    "www.vodafone.es/tienda/particulares/es/catalogo-moviles/sim-datos/",
    "www.vodafone.es/tienda/particulares/es/catalogo-fijos/",
    "www.vodafone.es/tienda/particulares/es/catalogo/ficha/particular/",
    "www.vodafone.es/tienda/particulares/es/clientes/catalogo/ficha/particular/",
    "www.vodafone.es/tienda/particulares/es/catalogo/ficha/prepago/movil/", //WCS-4817 - Compra Fácil > Nueva URL > Tarifas y Cobertura
    "www.vodafone.es/tienda/particulares/es/internet-y-fijo/tarifas-cobertura-fibra-adsl/", //WCS-4815 - Venta iPhone XR > Formulario Compra Fácil
    "www.vodafone.es/tienda/particulares/es/productos/iphone-xr/", //WCS-4927 - Compra Fácil > Nueva url
    "www.vodafone.es/c/particulares/es/productos-y-servicios/television/",
    "www.vodafone.es/tienda/particulares/es/one/cobertura-fibra/",
    "www.vodafone.es/c/particulares/es/mas-con-vodafone/objetos-conectados/listado/",
    "www.vodafone.es/tienda/particulares/es/one/cobertura-adsl",
    "www.vodafone.es/tienda/particulares/es/one/cobertura-one-basico",
    "www.vodafone.es/tienda/particulares/es/one/black-friday/",
    "www.vodafone.es/tienda/particulares/es/one/cyber-monday/",
    "www.vodafone.es/c/particulares/es/productos-y-servicios/internet-y-fijo/fibra-fijo-1/",
    "www.vodafone.es/c/particulares/es/productos-y-servicios/internet-y-fijo/fibra-fijo-2/",
    "www.vodafone.es/static/microsites/particulares-terminales-test/index.html",
    "www.vodafone.es/static/microsites/particulares-resultado-fibra-test/index.html",
    "www.vodafone.es/tienda/particulares/es/one/haz-tu-paquete/",
    "www.vodafone.es/tienda/particulares/es/one/cobertura-fibra-regional",
    "www.vodafone.es/tienda/particulares/es/one/cobertura-adsl-regional",
    "www.vodafone.es/tienda/particulares/es/one/cobertura-one-basico-regional",
    "www.vodafone.es/c/particulares/es/productos-y-servicios/internet-y-fijo/",
    "www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/configurar-oferta", //WCS-6283
    "www.vodafone.es/c/particulares/es/productos-y-servicios/abc-des-wyz/",
    "www.vodafone.es/c/particulares/es/mas-con-vodafone/servicios-para-tu-movil/tidal/",
    "www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/cobertura/one-basico/",
    "www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/cobertura/fibra/",
    "www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/cobertura/adsl/",
    "www.vodafone.es/c/particulares/es/productos-y-servicios/no-soy-cliente/destiny/",
    "www.vodafone.es/c/particulares/es/productos-y-servicios/no-soy-cliente/",
    "www.vodafone.es/c/particulares/es/mas-con-vodafone/objetos-conectados/v-home/",
    "www.vodafone.es/c/particulares/es/mas-con-vodafone/objetos-conectados/v-home/amazon-echo/",
    "www.vodafone.es/c/particulares/es/mas-con-vodafone/objetos-conectados/v-home/google-home/",
    "www.vodafone.es/c/particulares/es/",
    "www.vodafone.es/c/particulares/es/productos-y-servicios/moviles/moviles-5g/",
    "www.vodafone.es/c/conocenos/es/5g/",
    "www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/verano-50/",
    "/c/tienda-online/particulares/tarifas-moviles/",
    "/c/tienda-online/particulares/fibra-optica/",
    "/c/particulares/es/productos-y-servicios/internet-y-fijo/super-wifi/",
    "www.vodafone.es/c/fibra-tv-2/",
    "www.vodafone.es/c/particulares/es/productos-y-servicios/television/paquete-tv-1/2/",
    "www.vodafone.es/c/particulares/es/productos-y-servicios/television/paquete-tv-2/2/",
    "www.vodafone.es/c/particulares/es/productos-y-servicios/television/paquete-tv-3/2/",
    "www.vodafone.es/ca/tienda/particulares/es/informacion/promocion-prueba/",
    "www.vodafone.es/ca/tienda/particulares/es/productos/samsung-galaxy-note9/",
    "www.vodafone.es/ca/tienda/particulares/es/one-todo-en-uno/fibra-movil-tv/",
    "www.vodafone.es/ca/tienda/particulares/es/one-todo-en-uno/fibra-movil/",
    "www.vodafone.es/ca/tienda/particulares/es/one-todo-en-uno/contratar-television/",
    "www.vodafone.es/ca/tienda/particulares/es/one-todo-en-uno/television-con-one/",
    "www.vodafone.es/ca/tienda/particulares/es/movil/solo-sim/",
    "www.vodafone.es/ca/tienda/particulares/es/movil/ya-soy-cliente/",
    "www.vodafone.es/ca/tienda/particulares/es/movil/no-soy-cliente/",
    "www.vodafone.es/ca/tienda/particulares/es/productos/hbo-con-vodafone/",
    "www.vodafone.es/ca/tienda/particulares/es/one/todo-el-futbol/",
    "www.vodafone.es/ca/tienda/particulares/es/internet-y-fijo/internet-movil/",
    "www.vodafone.es/ca/tienda/particulares/es/internet-y-fijo/fibra-fijo/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo/ficha/particular/movil/samsung/galaxy-s9-azul/",
    "www.vodafone.es/ca/tienda/particulares/es/clientes/catalogo-moviles/contrato/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo-moviles/pasate-a-contrato/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo/ficha/prepago/movil/tarjeta/sim_vodafone_internacional_smartphone/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo/ficha/prepago/movil/tarjeta/sim_yuser/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo/ficha/prepago/movil/tarjeta/sim_vodafone_internacional_voz/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo/ficha/prepago/movil/tarjeta/vodafone_fcil/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo/ficha/particular/movil/vodafone/r218/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo/ficha/particular/movil/vodafone/smart_tab_n8/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo/ficha/particular/movil/vodafone-en-tu-casa/adoc-k1/", // "vodafoneyu.es",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo/ficha/prepago/movil/tarjeta/sim_yuser/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo/ficha/prepago/movil/tarjeta/sim_superyuser/",
    "www.vodafone.es/ca/tienda/particulares/es/one/configurar-oferta/",
    "www.vodafone.es/ca/tienda/particulares/es/informacion/ventajas-comprar-online/", //WCS-3863 - Compra Fácil > Nueva URL > Estrena Smartphone
    "www.vodafone.es/ca/tienda/particulares/es/productos/promocion-smartphone/", //AACF-130 - Indra nos pide que carguemos compra fácil en esas url
    "10.225.122.241:22080/tienda/particulares/es/catalogo/ficha/particular",
    "10.225.122.241:22080/tienda/autonomos/es/me/catalogo/ficha/microempresa",
    "10.225.239.197:10080/tienda/particulares/es/catalogo/ficha/particular",
    "10.225.239.197:10080/tienda/autonomos/es/me/catalogo/ficha/microempresa", //AACF-134 veo que cuando lo pones en catalan se suprime el /ca, asique lo he puesto de su forma y de la que nos funciona por si lo cambian en un futuro

    //WCS-4399 240918
    "www.vodafone.es/ca/c/particulares/es/productos-y-servicios/vodafone-one/", // subieron el form a otra url (la que esta debajo de esta)
    "www.vodafone.es/ca/c/particulares/es/productos-y-servicios/vodafone-one-resideno/", // WCS-4509 - Formulario Compra Fácil > Nuevas urls
    "www.vodafone.es/ca/static/microsites/particulares-tarifas-test/index.html",
    "www.vodafone.es/ca/static/microsites/particulares-fibra-fijo-test-v2/index.html",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo-moviles/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo-moviles/prepago/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo-tablets/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo-modems/contrato/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo-moviles/sim-datos/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo-fijos/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo/ficha/particular/",
    "www.vodafone.es/ca/tienda/particulares/es/clientes/catalogo/ficha/particular/",
    "www.vodafone.es/ca/tienda/particulares/es/catalogo/ficha/prepago/movil/", //WCS-4817 - Compra Fácil > Nueva URL > Tarifas y Cobertura
    "www.vodafone.es/ca/tienda/particulares/es/internet-y-fijo/tarifas-cobertura-fibra-adsl/", //WCS-4815 - Venta iPhone XR > Formulario Compra Fácil
    "www.vodafone.es/ca/tienda/particulares/es/productos/iphone-xr/", //WCS-4927 - Compra Fácil > Nueva url
    "www.vodafone.es/ca/tienda/particulares/es/one/cobertura-fibra/",
    "www.vodafone.es/ca/c/particulares/es/mas-con-vodafone/objetos-conectados/listado/",
    "www.vodafone.es/ca/tienda/particulares/es/one/cobertura-adsl",
    "www.vodafone.es/ca/tienda/particulares/es/one/cobertura-one-basico",
    "www.vodafone.es/ca/tienda/particulares/es/one/black-friday/",
    "www.vodafone.es/ca/tienda/particulares/es/one/cyber-monday/",
    "www.vodafone.es/ca/c/particulares/es/productos-y-servicios/movil/contrato/tarifas-contrato/",
    "www.vodafone.es/ca/c/particulares/es/productos-y-servicios/internet-y-fijo/fibra-fijo-1/",
    "www.vodafone.es/ca/c/particulares/es/productos-y-servicios/internet-y-fijo/fibra-fijo-2/",
    "www.vodafone.es/ca/static/microsites/particulares-terminales-test/index.html",
    "www.vodafone.es/ca/static/microsites/particulares-resultado-fibra-test/index.html",
    "www.vodafone.es/ca/tienda/particulares/es/one/haz-tu-paquete/",
    "www.vodafone.es/ca/tienda/particulares/es/one/cobertura-fibra-regional",
    "www.vodafone.es/ca/tienda/particulares/es/one/cobertura-adsl-regional",
    "www.vodafone.es/ca/tienda/particulares/es/one/cobertura-one-basico-regional",
    "www.vodafone.es/ca/c/particulares/es/productos-y-servicios/internet-y-fijo/",
    "www.vodafone.es/ca/c/particulares/es/productos-y-servicios/vodafone-one/configurar-oferta", //WCS-6283
    "www.vodafone.es/ca/c/particulares/es/productos-y-servicios/abc-des-wyz/",
    "www.vodafone.es/ca/c/particulares/es/mas-con-vodafone/servicios-para-tu-movil/tidal/",
    "www.vodafone.es/ca/c/particulares/es/productos-y-servicios/vodafone-one/cobertura/one-basico/",
    "www.vodafone.es/ca/c/particulares/es/productos-y-servicios/vodafone-one/cobertura/fibra/",
    "www.vodafone.es/ca/c/particulares/es/productos-y-servicios/vodafone-one/cobertura/adsl/",
    "www.vodafone.es/ca/c/particulares/es/productos-y-servicios/no-soy-cliente/destiny/",
    "www.vodafone.es/ca/c/particulares/es/productos-y-servicios/no-soy-cliente/",
    "www.vodafone.es/ca/c/particulares/es/mas-con-vodafone/objetos-conectados/v-home/",
    "www.vodafone.es/ca/c/particulares/es/mas-con-vodafone/objetos-conectados/v-home/amazon-echo/",
    "www.vodafone.es/ca/c/particulares/es/mas-con-vodafone/objetos-conectados/v-home/google-home/",
    "www.vodafone.es/ca/c/particulares/es/",
    "www.vodafone.es/c/empresas/autonomos/es/tienda/packs-one-profesional/te-ayudamos-a-configurar-tu-oferta/",
    "www.vodafone.es/c/tienda-online/particulares/vodafone-one/",
    "www.vodafone.es/c/particulares/es/productos-y-servicios/informacion/black-friday/prueba/",
    "www.vodafone.es/c/particulares/es/productos-y-servicios/movil/gamers", //WCS-9262
    "www.vodafone.es/c/pruebas/", //WCS-9285
    "www.vodafone.es/c/particulares/es/productos-y-servicios/informacion/navidad/",
    "www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/",
  ]
  
  if(urls.includes(window.location.href)) return true

  return false
}
