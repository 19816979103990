export const loadConversionConbertura = function (idlead, fn) {
  idlead = idlead || globalThis.DFI.leadId || globalThis.DFI.getLeadId()

  globalThis.DAL.Ajax({
    type: "script",
    url: globalThis.DFI.global.tagConversion + idlead,
  });

  if (fn) {
    fn();
  }
}
