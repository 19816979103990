export function existDFI_client() {
    return new Promise((resolve, reject) => {
      let counter = 0;
      const MAX_COUNTER = 100;
      const interval = setInterval(() => {
        counter++;
        if (
          globalThis.DFI_client &&
          typeof globalThis.DFI_client === "object" 
        ) {
          // @ts-ignore
          clearInterval(interval);
          resolve(true);
        } else if (counter > MAX_COUNTER) {
          // @ts-ignore
          clearInterval(interval);
          reject(new Error('DFI_client no existe'));
        }
      }, 400);
    });
  }