/**
 * Adjuntar todos los arrays de objetos en uno recorrer los indices,Comprobando la url si existe y dependiendo de la raiz en cual esta
 * devuelve la info necesaria.
 */
export function declarativeByUrlBinary() {

    const objTotal = [
        {
          urlPre: [
            {
              id: 'http://localhost:9006/',
              site: 'LOCALHOST',
            },
            {
              id: 'https://www.vodafone.es/c/particulares/es/productos-y-servicios/movil/contrato/tarifas-contrato/test-walmeric/',
              site: 'TestWalmeric',
            }
          ]
        }, 
        {
          sinProd: [
            {
              id: 'presupuesto-tarifas-adsl-fibra.rastreator',
              site: 'Performance',
            },
            {
              id: 'vodafone.rastreatortest.com/',
              site: 'Performance',
            },
            {
              id: 'ono.rastreatortest.com/',
              site: 'Performance',
            },
            {
              id: 'tarifas-adsl-fibra.rastreatortest.com/',
              site: 'Performance',
            },
            {
              id: 'ilead.itrack.it/clients/ESVodafone/',
              site: 'Performance',
            },
            {
              id: 'vodafone.comparatodo.es/gracias',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/c/particulares/es/#',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/ca/tienda/particulares/es/one/configurar-oferta/',
              site: 'TOL',
            },
            {
              id: 'www.vodafone.es/ficticio/?other=facebook.chat.bot:cp-vdf_tol_continuidad:dt-20181010:wn-tol:sp-faceb',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolinteresesprospectinglead.adintereses.androidvoz.huaweipsmart.marzo.v1noemail',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtoldmpretargetinglead.adTOLCU57.CORTA2p.marzo.v1nomail',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolvozlead.adretargetinglead.adwca.landingSamsung.S9.v3.retargetingmarzo.18',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/tarifas-movil/',
              site: 'Performance',
            },
            {
              id: 'pre.roams.es/ofertas/vodafone/hbo-gratis/',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/huawei-p20-azul-128gb-313347/',
              site: 'TOL',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/clientes/catalogo/ficha/particular/movil/apple_iphone/xs-max-256gb-gris-espacial/',
              site: 'TOL',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/clientes/catalogo/ficha/particular/movil/apple_iphone/xs-max-256gb-gris-espacial/',
              site: 'TOL',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/one-todo-en-uno/lineas-adicionales/',
              site: 'TOL',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles-alcatel/',
              site: 'TOL',
            },
            {
              id: 'www.vodafone.es/static/microsites/smartphones-clientes-2/index.html',
              site: 'TOL',
            },
            {
              id: 'localhost:4200/c/tienda-online/particulares/contratacion/pago-mensual/',
              site: 'TOL',
            },
            {
              id: 'localhost:4200/c/tienda-online/particulares/contratacion/pago-inicial/',
              site: 'TOL',
            },
            {
              id: 'www.ono.es/',
              site: 'TOL',
            },
            {
              id: 'oferta.vodafone.es/test/regional-amp/',
              site: 'Performance',
            },
            {
              id: 'bit.vodafone.es/',
              site: 'BIT',
            },
            {
              id: 'bit.vodafone.es/tarifa-movil/',
              site: 'BIT',
            },
            {
              id: 'pre.roams.es/vodafone/',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/',
              site: 'WebPublica',
            },
            {
              id: 'www.vodafone.es/static/microsites/particulares-fibra-gratis/index.html',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/static/microsites/particulares-segundaslineas/index.html',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/static/microsites/particulares-vodafoneonefibra-gratis/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.comparatodo.es/gracias',
              site: 'Performance',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_VodafoneOneMini_FixedInternetMobile_0_0',
              site: 'Performance',
            },
            {
              id: 'vodafone.comparatodo.es/gracias',
              site: 'Performance',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_VodafoneOneMini_FixedInternetMobile_0_0',
              site: 'Performance',
            },
          ]
        },
        {
          urlMovil:[
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/alcatel-',
              site: 'Catalogo Moviles',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-',
              site: 'Catalogo Moviles',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/land-rover-',
              site: 'Catalogo Moviles',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/lg-',
              site: 'Catalogo Moviles',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/motorola-',
              site: 'Catalogo Moviles',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/nokia-',
              site: 'Catalogo Moviles',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/palm-phone-',
              site: 'Catalogo Moviles',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-',
              site: 'Catalogo Moviles',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/sony-',
              site: 'Catalogo Moviles',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/vodafone-smart-',
              site: 'Catalogo Moviles',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/wiko-',
              site: 'Catalogo Moviles',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/xiaomi-',
              site: 'Catalogo Moviles',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles-alcatel/',
              site: 'Catalogo Moviles',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles-land-rover/',
              site: 'Catalogo Moviles',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles-nokia/',
              site: 'Catalogo Moviles',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles-palm/',
              site: 'Catalogo Moviles',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles-wiko/',
              site: 'Catalogo Moviles',
            },
            {
              id: 'oferta.vodafone.es/extra/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/ilimitada/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/ilimitada-super/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/ilimitada-total/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/mini/',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/static/microsites/particulares-landing-iphone-xs/index.html',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/tarifas-moviles/',
              site: 'TOL',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/tarifas-moviles/tarifa-movil-1/',
              site: 'TOL',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/tarifas-moviles/tarifa-movil-2/',
              site: 'TOL',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/tarifas-moviles/tarifa-movil-3/',
              site: 'TOL',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/tarifas-moviles/tarifa-movil-4/',
              site: 'TOL',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/tarifas-moviles/tarifa-movil-5/',
              site: 'TOL',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/confirmacion-pedido/',
              site: 'Transaccional',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/contratacion/datos-personales/',
              site: 'Transaccional',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/contratacion/detalles-envio/',
              site: 'Transaccional',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/contratacion/lineas/',
              site: 'Transaccional',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/moviles/moviles-5g/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/soy-cliente/',
              site: 'WebPublica',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/contratacion/pago-mensual/',
              site: 'Transaccional',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/contratacion/pago-inicial/',
              site: 'Transaccional',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/ya-soy-cliente/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/yu/social-pass-gratis/',
              site: 'Web Publica',
            },
            {
              id: 'roams.es/tarifas-movil/vodafone/tarifa-ilimitada-total/',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-movil/vodafone/tarifa-mega-yuser-prepago/',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-movil/vodafone/tarifa-mini-s/',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-movil/vodafone/tarifa-plan-red-xl-9/',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-movil/vodafone/tarifa-plan-smart-s/',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-movil/vodafone/tarifa-red-l/',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-movil/vodafone/tarifa-smart-s/',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-movil-internet-tv/vodafone/tarifa-vodafone-one-fibra-ono-50mb-plan-movil-s/',
              site: 'Performance',
            },
            {
              id: 'ww.adslred.com/tarifas-moviles/extra',
              site: 'Performance',
            },
            {
              id: 'ww.adslred.com/tarifas-moviles/ilimitada',
              site: 'Performance',
            },
            {
              id: 'ww.adslred.com/tarifas-moviles/mini',
              site: 'Performance',
            },
            {
              id: 'ww.adslred.com/tarifas-moviles/moviles-desde-1euro',
              site: 'Performance',
            },
          ]
        },
        {
          urlFibra:
          [
            {
              id: 'oferta.vodafone.es/fibra100mb/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/fibra600mb/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/multi/',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/internet-y-fijo/',
              site: 'Web Publica',
            }, //------
            {
              id: 'ilead.itrack.it/clients/ESVodafone/c19498/index.asp',
              site: 'Performance',
            },
            {
              id: 'ilead.itrack.it/clients/ESVodafone/c19498/index_frm.asp',
              site: 'Performance',
            },
            {
              id: 'vodafone.comparatodo.es/',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-fibra-50-mb',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-yuser-600-mb',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-internet/vodafone/tarifa-vodafone-1-gb-simetrico-fibra-ono/',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-movil-internet-tv/vodafone/tarifa-vodafone-one-light/',
              site: 'Performance',
            },
            {
              id: 'ww.adslred.com/fibra/fibra-100mb',
              site: 'Performance',
            },
            {
              id: 'ww.adslred.com/fibra/fibra-600mb',
              site: 'Performance',
            },
            {
              id: 'www.adslred.com/fibra/fibra_600',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-50-mb-cinefans',
              site: 'Performance',
            },
            {
              id: 'new.vodafoneofertas.com/fibra/fibra-100mb',
              site: 'Performance',
            },
          ]
        },
        {
          urlMovilMasFibra:[
            {
              id: 'oferta.vodafone.es/descuento-ilimitada/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/one-extra/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/one-ilimitada-super/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/one-ilimitada-total/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/promo-smartphone-1/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/promo-smartphone-2/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/promo-smartphone-3/',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/tienda/ofertas-y-promociones/one-ilimitada-super/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/one/cobertura/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/cobertura/adsl/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/cobertura/adsl/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/cobertura/one-basico/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/paquete-one-1/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/paquete-one-2/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/paquete-one-3/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/paquete-one-4/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/paquete-one-5/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/paquete-one-6/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/verano-50/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/movil/catalogo-movil-fibra/',
              site: 'Web Publica',
            },
            {
              id: 'oferta.vodafone.es/fibra-tv/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/fibra-tv/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/descuento-especial/?v5',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/fibra-one-m/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/descuento-especial/?g6',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/descuento-especial/?m1',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/descuento-especial/?o2',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/descuento-especial/?y3',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/descuento-especial/?e4',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/descuento-especial',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/regional/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/contacto-vodafone/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/vodafone-one',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-test-velocidad/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-one-tv/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-vodafone-one/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-promo-one/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/configurador-recomendador-paquete-tarifas-oferta-one/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/navega-sin-limites',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one-todo-en-uno/fibra-movil-tv/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one-todo-en-uno/fibra-movil',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one-todo-en-uno/contratar-television/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/internet-y-fijo/tarifas-cobertura-fibra-adsl/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one-todo-en-uno/television-con-one/?mostrarGE=true',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/movil/ya-soy-cliente/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/movil/no-soy-cliente/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/hbo-con-vodafone/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one/todo-el-futbol/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/internet-y-fijo/fibra-fijo/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/informacion/ventajas-comprar-online/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one/internet-en-casa/?mostrarGE=true',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one-todo-en-uno/comparador-one/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one/configurar-oferta/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one/cobertura-fibra',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one/cobertura-adsl',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one/cobertura-one-basico',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one/black-friday/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one/cyber-monday',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one/cobertura/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one-todo-en-uno/configurar-oferta/?mostrarGE=true',
              site: 'TOL',
            },
            {
              id: 'vodafone.estufibra.com/',
              site: 'Performance',
            },
            {
              id: 'adslred.com/one',
              site: 'Performance',
            },
            {
              id: 'ofertaono.com/fibra_movil/',
              site: 'Performance',
            },
            {
              id: 'ofertaono.com/',
              site: 'Performance',
            },
            {
              id: 'ofertaono.com/fibra_movil_tv/',
              site: 'Performance',
            },
            {
              id: 'ofertaono.com/fibra_movil/',
              site: 'Performance',
            },
            {
              id: 'ofertaono.com/fibra_tv/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/descuento-especial',
              site: 'Performance',
            },
            {
              id: 'vodafone-one.oferta-fibra.com/',
              site: 'Performance',
            },
            {
              id: 'tuvodafoneone.com/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/oferta50/',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-one',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-terminales-test/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one-todo-en-uno/fibra-ono-movil/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one-todo-en-uno/television-con-one/',
              site: 'TOL',
            },
            {
              id: 'vodafoneonefibra.es/tarifa-vodafone-one',
              site: 'Performance',
            },
            {
              id: 'vodafoneonefibra.es/',
              site: 'Performance',
            },
            {
              id: 'ono.altas-internet.com/Ono_VodafoneOne',
              site: 'Performance',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_VodafoneOne',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-movil-internet/',
              site: 'Performance',
            },
            {
              id: 'pre.roams.es/tarifas-movil-internet/',
              site: 'Performance',
            },
            {
              id: 'roams.es/vodafone/beneficios-cliente-vodafone/',
              site: 'Performance',
            },
            {
              id: 'roams.es/vodafone/portabilidad-de-movistar-a-vodafone/',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-movil-internet-tv/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/oferta30/',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolcomportamientosprospectinglead.adtelecommutingone.promocion.30.10gb.v2',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolcomportamientosprospectinglead.adyoungfamiliesone.promocion.30.10gb',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolcomportamientosprospectinglead.adyoungfamiliesone.promocion.30.10gb.v2',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtoloneprospectinglead.adcartera.mobilesuscribers.bonoone.ofertaprivada.30.12meses.v1.bono',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolcomportamientosprospectinglead.adtelecommutingone.promocion.30.10gb',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtoloneprospectinglead.adcartera.mobilesuscribers.bonoone.ofertaprivada.30.12meses.v2.bono',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtoloneprospectinglead.adcartera.mobilesuscribers.bonoone.ofertaprivada.30.12meses.v3.bono',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolinteresesprospectinglead.adtelcoone.promocion.30.10gb',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtoldmpprospectinglead.adTOLCU32one.promocion.30.enero.10gb.privada',
              site: 'Performance',
            },
            {
              id: 'ono.estufibra.com/',
              site: 'Performance',
            },
            {
              id: 'www.comparar-adsl.com/frm/vodafone.html',
              site: 'Performance',
            },
            {
              id: 'ilead.itrack.it/clients/ESVodafone/c19498/index.asp',
              site: 'Performance',
            },
            {
              id: 'ilead.itrack.it/clients/ESVodafone/c19754/index.asp',
              site: '',
            },
            {
              id: 'ono.estufibra.com/index',
              site: 'Performance',
            },
            {
              id: 'www.adslred.com/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es',
              site: 'Performance',
            },
            {
              id: 'vodafone-one.oferta-fibra.com/',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-one-extra',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-one-ilimitada',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-one-ilimitada-super',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-one-ilimitada-total-600-mb',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-one-mini',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-movil-internet-tv/vodafone/tarifa-vodafone-one-familia-m-fibra-ono-300mb-2-lineas-plan-movil-m/',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-movil-internet-tv/vodafone/tarifa-vodafone-one-fibra-ono-300mb-plan-movil-m/',
              site: 'Performance',
            },
            {
              id: 'ono.estufibra.com/',
              site: 'Performance',
            },
            {
              id: 'ono.estufibra.com/index',
              site: 'Performance',
            },
            {
              id: 'vodafone.estufibra.com/',
              site: 'Performance',
            },
            {
              id: 'ww.adslred.com/',
              site: 'Performance',
            },
            {
              id: 'ww.adslred.com/one-fibra/one-100mb-extra',
              site: 'Performance',
            },
            {
              id: 'ww.adslred.com/one-fibra/one-100mb-ilimitada-inicial',
              site: 'Performance',
            },
            {
              id: 'ww.adslred.com/one-fibra/one-600mb-ilimitada',
              site: 'Performance',
            },
            {
              id: 'ww.adslred.com/one-fibra/one-600mb-ilimitada-super',
              site: 'Performance',
            },
            {
              id: 'ww.adslred.com/one-fibra/one-600mb-ilimitada-total',
              site: 'Performance',
            },
            {
              id: 'www.adslred.com/',
              site: 'Performance',
            },
            {
              id: 'www.adslred.com/one/one_ilimitada_super_600',
              site: 'Performance',
            },
            {
              id: 'www.adslred.com/one/one_ilimitada_total_600',
              site: 'Performance',
            },
            {
              id: 'www.adslred.com/one_fibra/one_100_xs',
              site: 'Performance',
            },
            {
              id: 'www.adslred.com/one_fibra/one_extra_100',
              site: 'Performance',
            },
            {
              id: 'new.vodafoneofertas.com',
              site: 'Performance',
            },
            {
              id: 'new.vodafoneofertas.com/one-fibra/one-100mb-mini',
              site: 'Performance',
            },
            {
              id: 'ilead.itrack.it/clients/ESVodafone/c20308/index.asp',
              site: 'Performance',
            },
            {
              id: 'new.vodafoneofertas.com/',
              site: 'Performance',
            },
          ]
        },
        {
          urlFibraNew:[
            {
              id: 'new.vodafoneofertas.com/fibra/fibra-100mb',
              site: 'Performance',
            },
          ]
        },
        {
          urlTelevision:[
            {
              id:"https://www.vodafone.es/c/television/pack-seriefans/",
              site: 'Web Publica',
            },
            {
              id:"https://www.vodafone.es/c/television/pack-familyfans/",
              site: 'Web Publica',
            },
            {
              id:"https://www.vodafone.es/c/television/pack-deportes/",
              site: 'Web Publica',
            },
            {
              id:"https://www.vodafone.es/c/television/pack-mas-cine/",
              site: 'Web Publica',
            },
            {
              id: 'oferta.vodafone.es/tv-packs/',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/television/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/television/hbo-espana/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/television/paquete-tv-1/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/television/paquete-tv-2/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/television/paquete-tv-3/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/television/paquete-tv-4/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/television/paquete-tv-5/',
              site: 'Web Publica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/television/xsell/',
              site: 'Web Publica',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-fibra-tv/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/particulares-fibra-tv/',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/futbol/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/futbol/partidos/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/netflix/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/hbo-espana/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/hbo-espana/juego-de-tronos/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/vodafone-tv-online/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/diales-vodafone-tv/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/canales-vodafone-tv/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/4K/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/filmin/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/funcionalidades/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/contenidos-television/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/contenidos-television/cine/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/contenidos-television/series/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/contenidos-television/infantil/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/contenidos-television/entretenimiento/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/contenidos-television/fear-the-walking-dead/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/pack-cine/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/contenidos-television/axn-now/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/television/contenidos-television/series/el-misterio-de-hanging-rock/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.estufibra.com/television',
              site: 'Performance',
            },
            {
              id: 'www.adslred.com/tv',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/tienda/ofertas-y-promociones/amazon-prime/',
              site: 'WebPublica',
            },
            {
              id: 'www.ofertaono.com/tv/',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolhbolead.adprospectinghbo.abierta.serieshbo.marzo.v1',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolhbolead.adprospectinghbo.abierta.serieshbo.marzo.v2',
              site: 'Performance',
            },
            {
              id: 'ilead.itrack.it/clients/ESVodafone/c19159/index.asp',
              site: 'Performance',
            },
            {
              id: 'ilead.itrack.it/clients/ESVodafone/c19159/index_frm.asp',
              site: 'Performance',
            },
            {
              id: 'ilead.itrack.it/clients/ESVodafone/c19376/index.asp',
              site: 'Performance',
            },
            {
              id: 'ilead.itrack.it/clients/ESVodafone/c19376/index_frm.asp',
              site: 'Performance',
            },
            {
              id: 'ilead.itrack.it/clients/ESVodafone/c19754/index.asp',
              site: 'Performance',
            },
            {
              id: 'ilead.itrack.it/clients/ESVodafone/c19754/index_frm.asp',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-50-mb-documentales',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-50-mb-serielovers',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-one-extra-cinefans-serielovers',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-one-ilimitada-cinefans-serielovers',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-one-mini-documentales',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-one-mini-serielovers',
              site: 'Performance',
            },
            {
              id: 'vodafone.estufibra.com/television',
              site: 'Performance',
            },
            {
              id: 'www.adslred.com/tv/vodafone-tv',
              site: 'Performance',
            },
            {
              id: 'www.adslred.com/tv/tv_total',
              site: 'Performance',
            },
          ]
        },
        {
          sinProdIgual: [
            {
              id: 'https://roams.es/vodafone/',
              site: 'Performance',
            },
          ]
        },
        {
          urlsInternet: [
            {
              id: 'oferta.vodafone.es/ofertas-cliente/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/fibra300mb/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/gratis-fibra',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/one-fibra-gratis/',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-fibra-oferta-exclusiva/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-fibra-gratis/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-adsl-gratis/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-vodafoneonefibra-gratis/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-fibra50mb/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-fibra300mb/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/particulares-fibra-gratis/',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/vodafoneonefibra-gratis/',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/oferta-exclusiva/',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/internet-y-fijo/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/internet-y-fijo/descubre-la-fibra-optica-de-vodafone/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/internet-y-fijo/descubre-la-fibra-optica-de-vodafone/fibra-1gbps/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/internet-y-fijo/solo-telefono-fijo/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/internet-y-fijo/promocion-mifi/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/conocenos/test-velocidad-adsl/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/internet-y-fijo/internet-movil/',
              site: 'TOL',
            },
            {
              id: 'adslred.com/fibra',
              site: 'Performance',
            },
            {
              id: 'ofertaono.com/fibra/',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-fibra',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/adsl',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/fibra',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/ono/fibra',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/internet',
              site: 'Performance',
            },
            {
              id: 'comunicacion.kelisto.es/landings/ono/ono-fibra',
              site: 'Performance',
            },
            {
              id: 'vodafone.comparatodo.es/',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-migraciones-fibra-directa/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafoneonefibra.es/tarifa-fibra-ono-50-mb/',
              site: 'Performance',
            },
            {
              id: 'vodafoneonefibra.es/tarifa-vodafone-1-gb-simetrico-fibra-ono/',
              site: 'Performance',
            },
            {
              id: 'vodafoneonefibra.es/tarifa-fibra-ono-50-mb-tv/',
              site: 'Performance',
            },
            {
              id: 'ono.altas-internet.com/Ono_Fibra',
              site: 'Performance',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_VodafoneFibra',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-fijo/',
              site: 'Performance',
            },
            {
              id: 'roams.es/vodafone/configurar-router-vodafone/',
              site: 'Performance',
            },
            {
              id: 'roams.es/vodafone/mapa-cobertura-vodafone/',
              site: 'Performance',
            },
            {
              id: 'pre.roams.es/tarifas-internet-tv/',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-internet/',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-internet-tv/',
              site: 'Performance',
            },
            {
              id: 'roams.es/vodafone/ofertas-nuevo-cliente-vodafone/',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolinteresesprospectinglead.adtelco2p.marzo.v1',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtoldmpretargetinglead.adTOLCU57.MEDIA2p.marzo.v2',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtoldmpretargetinglead.adTOLCU57.LARGA2p.marzo.v2',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtoldmpretargetinglead.adTOLCU57.LARGA2p.marzo.v1',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/static/microsites/particulares-resultado-fibra-test/index.html',
              site: 'TOL',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/contratacion',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/contratacion/consulta-de-cobertura/',
              site: 'Transaccional',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/contratacion/detalles-envio/',
              site: 'Transaccional',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/contratacion/instalacion/',
              site: 'Transaccional',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/contratacion/lineas/',
              site: 'Transaccional',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/contratacion/pago-mensual/',
              site: 'Transaccional',
            },
          ]
        },
        {
          urlsInternetIgual: [
            {
              id: 'https://ono.estufibra.com/internet',
              site: 'Performance',
            },
          ]
        },
        {
          urlMovilMasFibraIgual: [
            {
              id: 'roams.es/companias-telefonicas/vodafone/cobertura/',
              site: 'Performance',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/empresas/',
              site: 'Performance',
            },
            {
              id: 'power.roams.es/companias-telefonicas/vodafone/',
              site: 'Performance',
            },
            {
              id: 'pre.roams.es/companias-telefonicas/vodafone/',
              site: 'Performance',
            },
          ]
        },
        {
          urlsIot: [
            {
              id: 'oferta.vodafone.es/v-vodafone/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/v-home/',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/c/particulares/es/mas-con-vodafone/objetos-conectados/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-apple-watch/index.html',
              site: 'Performance',
            },
            {
              id: 'eshop.v.vodafone.com/es',
              site: 'Eshop',
            },
            {
              id: 'eshop.v.vodafone.com/es/vhome',
              site: 'Eshop',
            },
            {
              id: 'oferta.vodafone.es/v-home/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/v-vodafone/',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/static/microsites/particulares-apple-watch/index.html',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/mas-con-vodafone/objetos-conectados/',
              site: 'WebPublica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/mas-con-vodafone/objetos-conectados/listado/',
              site: 'WebPublica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/mas-con-vodafone/objetos-conectados/v-home/',
              site: 'WebPublica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/mas-con-vodafone/objetos-conectados/v-home/amazon-echo/',
              site: 'WebPublica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/mas-con-vodafone/objetos-conectados/v-home/google-home/',
              site: 'WebPublica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/mas-con-vodafone/objetos-conectados/vodafone-onenumber/',
              site: 'WebPublica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/mas-con-vodafone/objetos-conectados/vodafone-onenumber/#start7',
              site: 'WebPublica',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/mas-con-vodafone/objetos-conectados/vodafone-onenumber/#startbrowser',
              site: 'WebPublica',
            },
          ]
        },
        {
          urlsOneIgual: [
            {
              id: 'https://www.adslred.com/#',
              site: 'Performance',
            },
          ]
        },
        {
          urlsVoz: [
            {
              id: 'oferta.vodafone.es/tarifa-red-s',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/tarifa-red-m',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/tarifa-red-l',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/iphone/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/samsung/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/huawei/',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/lg',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/sony',
              site: 'Performance',
            },
            {
              id: 'oferta.vodafone.es/tarifa-mini-m',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-migraciones/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-primeraslineas/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-segundaslineas/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-mas-lineas-datos/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-mas-lineas-vacaciones/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-oferta-exclusiva/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-descuento-unico/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-descuento-unico-2/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-red-s/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-red-m/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-red-l/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-mini-m/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/particulares-migraciones/',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/particulares-segundaslineas/',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/particulares-segundaslineas/',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/contrato/tarifas-contrato/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/contrato/tarifas-red/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/contrato/mas-lineas/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/contrato/bonos-de-navegacion/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/prepago-y-recargas/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/prepago-y-recargas/tarifas-de-prepago/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/prepago-y-recargas/tarifas-de-prepago/vodafone-yu/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/prepago-y-recargas/tarifas-de-prepago/vodafone-internacional/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/prepago-y-recargas/tarifas-de-prepago/vodafone-facil/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/prepago-y-recargas/recargas-y-saldo/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/prepago-y-recargas/recargas-y-saldo/participar/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/roaming-y-llamar-al-extranjero/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/roaming-y-llamar-al-extranjero/roaming-en-el-extranjero/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/roaming-y-llamar-al-extranjero/llamar-al-extranjero',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/roaming-y-llamar-al-extranjero/llamar-al-extranjero/llamadas-internacionales/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/nuevos-bonos',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/roaming-y-llamar-al-extranjero/llamar-al-extranjero/informacion-bonos',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/roaming-y-llamar-al-extranjero/llamar-al-extranjero/nueva-tarificacion/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/roaming-y-llamar-al-extranjero/llamar-al-extranjero/bono-internacional/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/roaming-y-llamar-al-extranjero/llamar-al-extranjero/consulta-tu-numero-destino/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/tourist-in-spain-vf/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/smartphones-smart/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/novedadesvodafone',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/nuevo-software-huawei/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/vodafone-pass/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/vodafone-pass/social/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/vodafone-pass/music/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/vodafone-pass/mapas/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/vodafone-pass/video/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/vodafone-pass/partners/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/vodafone-pass/chat/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/movil/lo-tenemos/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/internet-y-fijo/tarifas-internet-movil/internet-movil-contrato/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/internet-y-fijo/tarifas-internet-movil/internet-movil-contrato/mas-lineas-datos/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/internet-y-fijo/tarifas-internet-movil/internet-movil-contrato/vacaciones/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/internet-y-fijo/tarifas-internet-movil/internet-movil-prepago/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/moviles/huawei/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/moviles/sony/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/moviles/lg/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/moviles/samsung/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/moviles/apple-watch-serie-5/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/moviles/iphone-11/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/moviles/iphone-11/#catalogo',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/moviles/iphone-11-pro-max/	',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/moviles/iphone-11-pro-max/#iphonepro',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/moviles/samsung-galaxy-note-10/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/movil/gamers/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/c/particulares/es/productos-y-servicios/informacion/cyber-monday/',
              site: 'WebPublica',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/movil/solo-sim/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one-todo-en-uno/lineas-adicionales/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/promocion-smartphone/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/informacion/re-estrena/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/movil/renueva-movil-prepago/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/descubre-iphone7/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/huawei/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/sony/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/lg/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/samsung/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/catalogo-tablets/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/iphone-8/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/iphone-x/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/moviles-libres/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/informacion/dia-del-padre',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/informacion/dia-de-la-madre',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/one/san-valentin/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/samsung-galaxy-note9/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/pack-lg-k11-bts/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/iphone-xs/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/iphone-xr/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/apple-watch/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/informacion/promocion-movil/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/informacion/super-weekend/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/productos/tarifas-red-spotify/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/informacion/promocion-huawei/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/informacion/promocion-xsell/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/catalogo-moviles/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/clientes/catalogo-moviles/contrato/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/catalogo-moviles/pasate-a-contrato/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/catalogo-fijos/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/catalogo-modems/contrato/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/catalogo-moviles/sim-datos/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/catalogo/ficha/particular/movil/tarjeta/sim_contrato',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/catalogo-tablets/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/catalogo-moviles/prepago/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/c/tienda-online/particulares/catalogo-moviles',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/clientes/catalogo/ficha/particular/movil/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/catalogo/ficha/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/clientes/catalogo/ficha/particular/movil/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/catalogo/ficha/',
              site: 'TOL',
            },
            {
              id: 'adslred.com/moviles',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-landing-iphone-xs/index.html',
              site: 'Performance',
            },
            {
              id: 'ono.es/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/tienda/particulares/es/clientes/catalogo-moviles/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-pasate-contrato-yu/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/static/microsites/particulares-fibra-yuser-clientes/index.html',
              site: 'Performance',
            },
            {
              id: 'vodafone.es/c/tienda-online/particulares/catalogo-movil/',
              site: 'TOL',
            },
            {
              id: 'vodafone.es/c/tienda-online/particulares/catalogo-moviles/',
              site: 'TOL',
            },
            {
              id: 'vodafoneonefibra.es/tarifa-red-l/',
              site: 'Performance',
            },
            {
              id: 'vodafoneonefibra.es/tarifa-yuser-prepago/',
              site: 'Performance',
            },
            {
              id: 'vodafoneonefibra.es/samsung-galaxy-s9-plus',
              site: 'Performance',
            },
            {
              id: 'vodafoneonefibra.es/tarifa-smart-s/',
              site: 'Performance',
            },
            {
              id: 'vodafoneonefibra.es/tarifa-vodafone-bit-movil/',
              site: 'Performance',
            },
            {
              id: 'vodafoneonefibra.es/huawei-mate-20',
              site: 'Performance',
            },
            {
              id: 'vodafoneonefibra.es/iphone-xs-max',
              site: 'Performance',
            },
            {
              id: 'vodafoneonefibra.es/tarifa-vodafone-facil-prepago/',
              site: 'Performance',
            },
            {
              id: 'ono.altas-internet.com/Ono_RedS',
              site: 'Performance',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_MiniS',
              site: 'Performance',
            },
            {
              id: 'roams.es/vodafone/esim-vodafone/',
              site: 'Performance',
            },
            {
              id: 'roams.es/vodafone/vodafone-datos-ilimitados/',
              site: 'Performance',
            },
            {
              id: 'roams.es/vodafone/ofertas-vodafone-black-friday/',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-movil/',
              site: 'Performance',
            },
            {
              id: 'roams.es/vodafone/limite-consumo-vodafone/',
              site: 'Performance',
            },
            {
              id: 'roams.es/vodafone/plan-renove-vodafone/',
              site: 'Performance',
            },
            {
              id: 'roams.es/tarifas-movil-tv/',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolinteresesprospectinglead.adintereses.androidvoz.huaweip30.abril.v1',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolinteresesprospectinglead.adintereses.androidvoz.huaweip30.abril.v2',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolvozprospectinglead.adabiertavoz.huaweip30.abril.v2',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolvozprospectinglead.advoz.offline.ai.bonosamsungs10 .v1.marzo.bono',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolvozprospectinglead.adabiertavoz.huaweip30.abril.v1',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolvozprospectinglead.adabiertavoz.huaweipsmart.marzo.v1',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolvozprospectinglead.advoz.offline.bonosamsungs10 .v1.marzo.bono',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolvozprospectinglead.advoz.offline.ai.bonosamsungs10.v1.marzo.bono',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolvozprospectinglead.adabiertavoz.huaweipsmart.marzo.v2',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolinteresesprospectinglead.adintereses.androidvoz.huaweipsmart.marzo.v2noemail',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolvozprospectinglead.advoz.offline.bonosamsungs10.v1.marzo.bono',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtoldmpretargetinglead.adTOLCU19.CORTAvoz.huaweip30.marzo.v1',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtoldmpretargetinglead.adTOLCU19.MEDIAvoz.huaweip30.marzo.v1',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolvozprospectinglead.advoz.offline.ai.bonosamsungs10 .aud.b.marzo.bono',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolvozprospectinglead.advoz.offline.bonosamsungs10.aud.a.marzo.bono',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtoldmpretargetinglead.adTOLCU19.MEDIAvoz.huaweip30.marzo.v2pro',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolvozprospectinglead.advoz.offline.ai.bonosamsungs10.aud.b.marzo.bono',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtoldmpretargetinglead.adTOLCU19.CORTAvoz.huaweip30.marzo.v2',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtoldmpretargetinglead.adTOLCU19.CORTAvoz.huaweipsmart.marzo.v1noemail',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtoldmpretargetinglead.adTOLCU19.CORTAvoz.huaweipsmart.marzo.v2nomail',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolvozprospectinglead.advoz.offline.bonosamsungs10 .aud.a.marzo.bono',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtoldmpretargetinglead.adTOLCU19.MEDIAvoz.huaweipsmart.marzo.v2',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtolvozprospectinglead.adabierta.huaweivoz.navidad.huawei.v2',
              site: 'Performance',
            },
            {
              id: 'www.vodafone.es/ficticio/?smp=leadads:cp-20180327:sp-facebook:pl-fbtoldmpretargetinglead.adTOLCU19.MEDIAvoz.huaweipsmart.marzo.v1',
              site: 'Performance',
            }, 
          ]
        },
        {
          urlsVozIgual: [
            {}
          ]
        },
        {
          urlIgual: [
            {
              id: 'roams.es/companias-telefonicas/vodafone/cobertura/4g/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/cobertura/5g/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/cobertura/movil/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/guias/clientes/amazon-prime/',
              site: 'Performance',
              producto: 'television',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/guias/clientes/black-friday/',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/guias/clientes/fidelizacion/',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/guias/clientes/fidelizacion/#departamento-de-fidelizacion-vodafone',
              producto: 'movil + fibra',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/guias/internet-fibra/entrar-router/',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/guias/internet-fibra/velocidad/',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/guias/smartphones/iphone-11-pro-max/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/guias/telefonia/alargar-tonos-llamadas/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/guias/telefonia/compartir-datos/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/guias/telefonia/desvio-llamadas/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/guias/telefonia/esim/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/guias/telefonia/multisim/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/guias/telefonia/quitar-contestador/#desactivar-contestador-automatico-de-vodafone-fijo',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/guias/television/canal-dark/',
              site: 'Performance',
              producto: 'television',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/guias/television/liberar-decodificador/',
              site: 'Performance',
              producto: 'television',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/guias/tramites/dar-baja/',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/moviles/moviles-libres/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/ofertas/portabilidad/',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/portabilidad/vodafone-lowi/',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/tarifas/',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/tarifas/internet/internet-rural/',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'roams.es/companias-telefonicas/vodafone/tarifas/internet4g/',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'roams.es/dispositivos/lg/lg-v50-5g/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/vodafone/contact/generic/',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'roams.es/vodafone/huawei/huawei-p30-pro/contact/generic/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'vodafone.altas-internet.com/ClickToCallSecondNumber',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_Extra_Contract_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_Ilimitada_Contract_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_Mini_Contract_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_OneExtraIPeques_FixedInternetMobileTV_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_OneExtraIPequescon2lineas_FixedInternetMobileTV_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_OneIlimitada100MbIPeques_FixedInternetMobileTV_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_OneIlimitada100MbISeriefans_FixedInternetMobileTV_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_OneIlimitadaSuper600MbISeriefans_FixedInternetMobileTV_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_OneIlimitadaTotal1GbpsICinefanscon2lineas_FixedInternetMobileTV_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_OneIlimitadaTotal1GbpsIPeques_FixedInternetMobileTV_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_OneMiniIDocumentales_FixedInternetMobileTV_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_OneMiniIPeques_FixedInternetMobileTV_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_OneMiniISeriefans_FixedInternetMobileTV_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_TestingIF1_FixedInternet_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_TestingMC1_Contract_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_TestingMC2_Contract_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.beemy.es/',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'vodafoneofertas.com/',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'vodafoneofertas.com/moviles/ilimitada',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/oferta/particulares/fibra100mb/',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/c/oferta/particulares/fibra600mb/',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/c/oferta/particulares/ilimitada/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/oferta/particulares/ilimitada-super/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/oferta/particulares/ilimitada-total/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/oferta/particulares/one-extra/',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/oferta/particulares/one-ilimitada/',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/oferta/particulares/one-ilimitada-super/',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/oferta/particulares/one-ilimitada-total/',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/oferta/particulares/one-mini/',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/oferta/particulares/tv-packs/',
              site: 'Performance',
              producto: 'television',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/movil/gamers/',
              site: 'WebPublica',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/informacion/black-friday/',
              site: 'WebPublica',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/informacion/cyber-monday/',
              site: 'WebPublica',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/informacion/navidad/',
              site: 'WebPublica',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/informacion/navidad/#',
              site: 'WebPublica',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/internet-y-fijo/fibra-fijo-1/',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/internet-y-fijo/fibra-fijo-2/',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/moviles/apple-watch-serie-5/',
              site: 'WebPublica',
              producto: 'IOT',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/moviles/iphone-11/',
              site: 'WebPublica',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/moviles/iphone-11/#catalogo',
              site: 'WebPublica',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/moviles/iphone-11-pro-max/',
              site: 'WebPublica',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/moviles/iphone-11-pro-max/#iphonepro',
              site: 'WebPublica',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/moviles/samsung-galaxy-note-10/',
              site: 'WebPublica',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/television/',
              site: 'Performance',
              producto: 'television',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/vodafone-one/configurador-recomendador-paquete-tarifas-oferta-one/',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/consulta-de-cobertura/',
              site: 'TOL',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/fibra-optica/',
              site: 'TOL',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/tarifas-moviles/',
              site: 'TOL',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/vodafone-one/',
              site: 'TOL',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/vodafone-one/paquete-fibra-movil-1/',
              site: 'TOL',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/vodafone-one/paquete-fibra-movil-2/',
              site: 'TOL',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/vodafone-one/paquete-fibra-movil-3/',
              site: 'TOL',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/vodafone-one/paquete-fibra-movil-4/',
              site: 'TOL',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/vodafone-one/paquete-fibra-movil-5/',
              site: 'TOL',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/ca/c/particulares/es/',
              site: 'WebPublica',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/ca/c/particulares/es/productos-y-servicios/movil/contrato/mas-lineas/',
              site: 'WebPublica',
              producto: 'movil',
            },
            {
              id: 'www.vodafoneofertas.com/',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafoneofertas.com/fibra',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafoneofertas.com/fibra/fibra_100',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafoneofertas.com/fibra/fibra_600',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafoneofertas.com/moviles/extra',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafoneofertas.com/moviles/ilimitada',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafoneofertas.com/moviles/mini',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafoneofertas.com/one/one_ilimitada_inicial_100',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafoneofertas.com/one/one_ilimitada_super_600',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafoneofertas.com/one/one_ilimitada_total_600',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafoneofertas.com/one_fibra/one_extra_100',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafoneofertas.com/one_fibra/one_mini_100',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafoneofertas.com/tv/vodafone_tv',
              site: 'Performance',
              producto: 'television',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/',
              site: 'Catalogo Moviles',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/movil/catalogo-clientes/',
              site: 'WebPublica',
              producto: 'movil',
            },
            {
              id: 'vodafone.estufibra.com/internet',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/xiaomi-redmi-7a-negro-16gb-313767/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'vodafone.oferta-fibra.com/',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'vodafone.estufibra.com/movil',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/movil/contrato/mas-lineas/',
              site: 'WebPublica',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/internet-y-fijo/solo-telefono-fijo/',
              site: 'WebPublica',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-note10-plus-5g-negro-512gb-313703/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'presupuesto-tarifas-adsl-fibra.rastreator.com/resultados-comparativa',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/huawei-mate-20x-5g-verde-esmeralda-256gb-313655/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-s10-plus-negro-128gb-313594/',
              site: 'TOL',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-max-oro-256gb-313742/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-blanco-128gb-313752/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-max-plata-256gb-313741/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/conocenos/test-velocidad-adsl/',
              site: 'WebPublica',
              producto: 'movil + fibra',
            },
            {
              id: 'www.adslred.com/moviles/mini#moviles_desde_0',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-verde-medianoche-64gb-313725/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-negro-256gb-313753/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-negro-64gb-313746/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-rojo-64gb-313748/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/xiaomi-mi-note10-negro-128gb-313790/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-max-verde-medianoche-256gb-313743/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-xs-max-gris-espacial-256gb-313508/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-max-oro-64gb-313737/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-max-gris-espacial-256gb-313740/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-negro-128gb-313751/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-blanco-256gb-313754/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-gris-espacial-256gb-313726/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-plata-256gb-313727/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-blanco-64gb-313747/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/moviles/samsung/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-max-plata-512gb-313745/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-a50-negro-128gb-313608/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-oro-256gb-313728/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-max-gris-espacial-512gb-313744/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-s10-plus-blanco-128gb-313626/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-s10-5g-negro-256gb-313657/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/huawei-p30-pro-nacar-256gb-313622/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/huawei-p30-pro-negro-256gb-313621/',
              site: 'TOL',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-verde-medianoche-256gb-313733/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-gris-espacial-64gb-313722/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'ono.estufibra.com/internet',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-amarillo-64gb-313750/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-xr-negro-64gb-313518/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/moviles/huawei/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/huawei-p-smart-z-verde-esmeralda-64gb-313773/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-plata-64gb-313723/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/huawei-nova-5t-negro-128gb-313785/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-max-plata-64gb-313736/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/movil/vodafone-pass/',
              site: 'WebPublica',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-oro-64gb-313724/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles-vodafone/',
              site: 'Catalogo Moviles',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-s10-plus-negro-512gb-313606/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/huawei-p30-lite-negro-128gb-313620/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-max-verde-medianoche-64gb-313738/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/huawei-p30-pro-aurora-256gb-313623/',
              site: 'TOL',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-a10-negro-32gb-313676/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-fibra-50mb',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/xiaomi-redmi-7-negro-32gb-313685/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-note10-plus-aura-256gb-313708/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-max-gris-espacial-64gb-313735/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-8-gris-espacial-64gb-313263/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'oferta.vodafone.es/fibra-one-s/',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/huawei-p30-negro-128gb-313613/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles-xiaomi/',
              site: 'Catalogo Moviles',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/sony-xperia-1-negro-128gb-313674/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/xiaomi-mi-mix-3-5g-negro-128gb-313660/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_VodafoneFibra100Mb_FixedInternet_0_0',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-s10e-negro-128gb-313592/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/lg-q60-azul-64gb-313667/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-a90-5g-negro-128gb-313764/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-a70-negro-128gb-313654/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/lg-v50-thinq-negro-128gb-313656/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'vodafone.estufibra.com/index',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/xiaomi-mi-9-negro-128gb-313651/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-note10-plus-negro-256gb-313702/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-a40-negro-64gb-313607/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles-huawei/',
              site: 'Catalogo Moviles',
              producto: 'movil',
            },
            {
              id: 'vodafone.comparatodo.es/internet',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'vodafone.comparatodo.es/television',
              site: 'Performance',
              producto: 'television',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-11-pro-gris-espacial-512gb-313734/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-xs-max-gris-espacial-64gb-313505/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/sony-xperia-5-negro-128gb-313761/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'vodafone.comparatodo.es/internet-movil',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-s10-negro-128gb-313593/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/huawei-p30-pro-azul-128gb-313776/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/lg-g8s-thinq-negro-128gb-313668/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/moviles/lg/#browser',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.adslred.com/fibra/fibra_100',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'ono.estufibra.com/movil',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.adslred.com/tv/vodafone_tv',
              site: 'Performance',
              producto: 'television',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-fold-negro-512gb-313675/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/sony-xperia-10-azul-64gb-313604/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles-lg/',
              site: 'Catalogo Moviles',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-note10-negro-256gb-313701/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/catalogo/ficha/particular/movil/apple_iphone/xs-64gb-oro/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/fibra-con-300-mb',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/huawei-p-smart-2019-negro-64gb-313577/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/xiaomi-redmi-note-8t-azul-128gb-313778/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/vodafone-smart-v10-negro-32gb-313618/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'vodafone.altas-internet.com/Vodafone_VodafoneFibra600Mb_FixedInternet_0_0',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/clientes/catalogo/ficha/particular/movil/huawei/p20-pro/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/nokia-7.1-azul-32gb-313543/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-note9-negro-128gb-313481/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/xiaomi-mi-9-lite-negro-128gb-313780/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/apple-iphone-xs-gris-espacial-64gb-313498/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-a30s-negro-64gb-313766/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/adsl-30mb',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.adslred.com/one_fibra/one_ilimitada_600',
              site: 'Performance',
              producto: 'movil + fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/nokia-4.2-negro-32gb-313683/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/huawei-y6-2019-negro-32gb-313609/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-note10-aura-256gb-313707/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/moviles/sony/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/clientes/catalogo/ficha/particular/movil/huawei/mate-20-lite/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/catalogo/ficha/particular/movil/huawei/mate-20-pro-verde-esmeralda/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles-sony/',
              site: 'Catalogo Moviles',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/lg-k50s-azul-32gb-313775/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles-apple/',
              site: 'Catalogo Moviles',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/motorola-one-vision-azul-128gb-313687/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.adslred.com/fibra/fibra_1gb',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/huawei-y5-2019-negro-16gb-313688/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/tarifas-internet/vodafone/tarifa-fibra-vodafone-para-estudiantes/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/moviles/lg/#startapp',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/movil/contrato/tarifas-contrato/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/sony-xperia-xa2-plata-32gb-313345/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'oferta.vodafone.es/one-mini/',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/movil/contrato/mas-lineas/#',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-a20e-negro-32gb-313653/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/catalogo/ficha/particular/movil/samsung/galaxy-s9-plus-purpura/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/lg-k40s-azul-32gb-313774/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/productos/apple-watch/',
              site: 'Performance',
              producto: 'IOT',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles-samsung/',
              site: 'Catalogo movil',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/lg-g7-azul-64gb-313417/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/palm-phone-negro-32gb-313564/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/samsung-galaxy-a40-azul-64gb-313627/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.adslred.com/fibra/fibra_50',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'oferta.vodafone.es/estrena-smartphone/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/tarifas-fijo/vodafone/tarifa-vodafone-fijo/',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.adslred.com/moviles/ilimitada',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/lg-k40-azul-32gb-313615/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'comunicacion.kelisto.es/landings/vodafone/vodafone-fibra-1-gb',
              site: 'Performance',
              producto: 'fibra',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/clientes/catalogo/ficha/particular/movil/apple_iphone/7_32gb_plata/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/palm-phone-negro-32gb-313564#start/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/tarifas-movil/vodafone/tarifa-red-m/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.adslred.com/moviles/mini',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'vodafone.comparatodo.es/movil',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/sony-xperia-xz3-negro-64gb-313529/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/catalogo/ficha/particular/movil/huawei/p20-lite-azul/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/vodafone-smart-e9-negro-8gb-313584/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/catalogo/ficha/particular/movil/sony/xperia-xz3-negro/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/vodafone-smart-n10-azul-16gb-313624/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'oferta.vodafone.es/one-ilimitada/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.adslred.com/moviles/mini#',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles/huawei-mate-20-pro-azul-128gb-313532/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'oferta.vodafone.es/fibra50mb/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.adslred.com/one_fibra/one_mini_100',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.adslred.com/moviles/red_m',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/moviles/lg/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/movil/contrato/tarifas-contrato/tarifa-1/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/catalogo/ficha/particular/movil/apple_iphone/8_oro_64gb/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/television/netflix/',
              site: 'WebPublica',
              producto: 'movil',
            },
            {
              id: 'oferta.vodafone.es/contacto-vodafone/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/movil/contrato/tarifas-contrato/tarifa-3/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/catalogo/ficha/particular/movil/samsung/galaxy-j6-plus/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/tarifas-internet-tv/vodafone/tarifa-fibra-ono-300-mb/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/catalogo/ficha/particular/movil/samsung/galaxy-s9-purpura/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/moviles/huawei/#',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/catalogo/ficha/particular/movil/vodafone/smart-n9/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'roams.es/tarifas-internet-tv/vodafone/tarifa-fibra-ono-50-mb/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/tienda/particulares/es/clientes/catalogo/ficha/particular/movil/apple_iphone/xs-max-64gb-oro/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.adslred.com/one/one_100_xs',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.adslred.com/moviles/extra',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.adslred.com/one/one_ilimitada_inicial_100',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/particulares/es/productos-y-servicios/moviles/samsung/#',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'oferta.vodafone.es/descuento-especial/',
              site: 'Performance',
              producto: 'movil',
            },
            {
              id: 'www.vodafone.es/c/tienda-online/particulares/catalogo-moviles-motorola/',
              site: 'Catalogo Moviles',
              producto: 'movil',
            },
          ]
        },
      ]
  
      // Objeto creado para devolver el id correcto dependiendo de la raiz
      const objParaId = {
        urlPre : 'LocalHost',
        sinProd: 'Performace',
        urlMovil: 'movil',
        urlFibra: 'fibra',
        urlMovilMasFibra: 'movil + fibra',
        urlFibraNew: 'fibra',
        urlTelevision: 'television',
        sinProdIgual: 'performance',
        urlsInternet: 'Internet',
        urlsInternetIgual: 'Internet',
        urlMovilMasFibraIgual: 'movil + fibra',
        urlsIot: 'IOT',
        urlsOneIgual: 'One',
        urlsVoz: 'Voz',
        urlsVozIgual: 'Voz',
        urlIgual: 'Voz'
      }
      
    function busquedaBinnary(objTotal,left,right){
      if(left>right) return null;
      let changePos = Math.floor((left + right) / 2);
      for (const raizIndice in objTotal[changePos]) {
        for (let i = 0; i < objTotal[changePos][raizIndice].length; i++) {
            const obj = objTotal[changePos][raizIndice][i];
            if (globalThis.location.href.includes(obj.id)) {
                const param={  
                  raiz: raizIndice,
                  id: objParaId[raizIndice] || "Id no encontrado",
                  site: obj.site  
              };  
                return param
            }
        }
        // Si el id no se encuentra en la parte actual, continuar la búsqueda en los otros lados
        const resultadoIzquierdo = busquedaBinnary(objTotal, left, changePos - 1);
        if (resultadoIzquierdo !== null) return resultadoIzquierdo;
  
        const resultadoDerecho = busquedaBinnary(objTotal, changePos + 1, right);
        if (resultadoDerecho !== null) return resultadoDerecho;
    }
  
    //Si no encuentra nada devolvemos un null
    return false;
    }
  
   return busquedaBinnary(objTotal, 0, objTotal.length -1);
  
  }
  
  
  
  
  