import { getUrlParams } from "./getUrlParams";
import { getUrlSubParams } from "./getUrlSubParams";

export function getPlacement() {
    const paramsToCheck = ['cid', 'did'];
  
    for (const param of paramsToCheck) {
      try {
        const objUrl = getUrlParams();
        const subParams = getUrlSubParams(objUrl[param]);
  
        if (subParams && subParams.pl) {
          return subParams.pl;
        }
      } catch (error) {}
    }
  
    return '';
  }
  