import { loadScript } from "../utils"

export const adformLoader = function () {
  let counter = 0
  const interval = setInterval(function () {
    if (globalThis.Adform) {
      counter++
      // @ts-ignore
      clearInterval(interval)
      loadScript("https://track.adform.net/Serving/Cookie/?adfaction=getjsadfcookname=uid")
        .then(() => {
          globalThis.adForm_ready && globalThis.adForm_ready()
        })
    }
    if (counter > 10) {
      // @ts-ignore
      clearInterval(interval)
    }
  }, 500)
}