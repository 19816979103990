
import ErrorWalmeric from "../errorControl/errorWalmericPersonalized"
import { objectErrorWm } from "../errorControl/objectErrorWalmeric";
import { loadLib } from "./loadLib"

/**
 * 
 * @param {Array} libs Array de objetos con las librerías a cargar
 */
export async function loadAllLibs(libs) {

  
  try{
    const promises = libs.map(lib => loadLib(lib.url))
    await Promise.all(promises);
  }catch(error){
    const errorWalmeric= new ErrorWalmeric('Error al carga las librerias de walmeric', "Libreria","tienda.js",123);
    console.error(errorWalmeric)
    objectErrorWm(errorWalmeric)
  }
 
}
