import { velocityInternet } from "../funtionsRefactor/velocityInternet";

/**
 * Funcion que comprueba que este Piwik,DAL,Visitor cargado 
 * Nota: Es normal que falle en preproducción esto es debido a que en local VF no esta metiendo el tag de Adobe ni el el de mcvid
*/
export function waitForAnalyticsObjects() {
    return new Promise(async (resolve, reject) => {
      let counter = 0;
      var MAX_COUNTER = 100;

      const internet = await velocityInternet();
      if(internet <= 2){
        MAX_COUNTER = 500;
      }

      const interval = setInterval(() => {
        counter++;
        if (
          typeof globalThis.visitor === "object" &&
          globalThis.visitor &&
          typeof globalThis.DAL === "object" &&
          typeof globalThis.Piwik === "object" &&
          typeof globalThis.Piwik.getAsyncTracker() === "object"
        ) {
          // @ts-ignore
          clearInterval(interval);
          resolve(true);
        } else if (counter > MAX_COUNTER) {
          // @ts-ignore
          clearInterval(interval);
          reject(new Error('Analytics objects check timed out.'));
        }
      }, 400);
    })}