import { waitForAnalyticsObjects } from "./existAnalytics"

function getCookie(name) {
    var value = `; ${document.cookie}`;
    var parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

function getId() {
    let utagMainCookie = getCookie("utag_main");
    if (!utagMainCookie) {
        console.error("Error utag_main cookie ");
        return null;
    }
    let splitFirst = utagMainCookie.split("v_id:");
    if (splitFirst.length < 2) {
        console.error("Error utag_main cookie(menos de dos elementos).");
        return null;
    }
    let splitSecond = splitFirst[1].split("$");
    return splitSecond[0];
}

export const adobe_teallium = async ()=>{
    const libraries = await waitForAnalyticsObjects()
    // @ts-ignore
    if(libraries && getCookie("utag_main") != ""){
                var leadId = globalThis.Piwik.getAsyncTracker().getVisitorId();
                var weboid = getId();
                if (!weboid) {
                    console.error("No se ha encontrado el id de web.");
                    return;
                }
        return new Promise((resolve, reject) => {
                globalThis.DAL.Ajax({
                    type: "jsonp",
                    url: "https://t.womtp.com/epst/v2?idsite=5f28300c4f97c65b1c0e82e1974f35f2" + "&idvisitor=" + leadId + "&v=1" + "&weboid=" + weboid,
                }).done(function (value) {
                    resolve();
                    globalThis.DAL.Ajax({type: "jsonp",url:"https://collect.tealiumiq.com/event?tealium_account=vodafone&tealium_profile=es-main-cdp&tealium_event=page_view&tealium_visitor_id="+ weboid})
                });
            });
        };
}
    