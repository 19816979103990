export const getCookie = function (c_name) {
   const cookies = document.cookie.split(";").map(cookie => cookie.trim());
  for (const cookie of cookies) {
    const [name, value] = cookie.split("=");
    if (name === c_name) {
      return decodeURIComponent(value);
    }
  }
  return false;
}
