import { setVisitVars } from "../functionsTracking/functionConversions";
import { waitForAnalyticsObjects } from "./existAnalytics";
import { existDFI_client } from "./existDFI_client";

import { waitForVisitorObject } from "./existVisitor";

export async function loadMcvid() {
    try {
      const DFI_client=await existDFI_client()
      const Visitor=await waitForVisitorObject()
      const Analitycs=await waitForAnalyticsObjects();

     if(DFI_client && Visitor && Analitycs){
      const mcvid = globalThis.visitor.getMarketingCloudVisitorID();
      const leadId = globalThis.Piwik.getAsyncTracker().getVisitorId();
      const urlMcvid= "https://t.womtp.com/epst/v2?idsite=5f28300c4f97c65b1c0e82e1974f35f2" +"&idvisitor=" +leadId +"&v=1" +"&mcvid=" +mcvid

      globalThis.DAL.Ajax({
        type: "jsonp",
        url: urlMcvid,
      })
        .done(function (value) {
          //callback para la carga
          console.log("Carga Correcta MCVID y actualizacion de visitLabel")
           /*Seteamos el MCVID para VisitLabel*/
           globalThis.womtp.v_vars[8]=globalThis.v_var_8 = globalThis.visitor.getMarketingCloudVisitorID()
           globalThis.womtp.v_vars[9]=globalThis.v_var_9 = globalThis.visitor.getAnalyticsVisitorID()
           setVisitVars()
        })
        .fail(function () {
          console.error("Fallo en la llamada a MCVID")
        })
     }
      
    } catch (error) {
      console.error(error);
    }
  }
  