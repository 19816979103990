export const isDMPCartera = function () {
  const urls = [
    'www.vodafone.es/tienda/particulares/es/productos/particulares-fibra-gratis/?dmp=12626262:cp-acciondmp:cn-dmp:md-CU1&c_var_11=dmp-cu1',
    'www.vodafone.es/tienda/particulares/es/catalogo/ficha/particular/movil/lg/g7-plata/?subtipoContrato=1&idPrecio=997608621&c_var_11=dmp-cu2',
    'www.vodafone.es/tienda/particulares/es/productos/particulares-migraciones/?dmp=12626262:cp-acciondmp:cn-dmp:md-CU6&c_var_11=dmp-cu6',
    'www.vodafone.es/tienda/particulares/es/productos/particulares-fibra-gratis/?dmp=12626262:cp-acciondmp:cn-dmp:md-CU7&c_var_11=dmp-cu7',
    'www.vodafone.es/tienda/particulares/es/productos/particulares-segundaslineas/?dmp=12626262:cp-acciondmp:cn-dmp:md-CU8&c_var_11=dmp-cu8',
    'www.vodafone.es/static/microsites/particulares-fibra-clientes/index.html?dmp=12626262:cp-acciondmp:cn-dmp:md-CU9&c_var_11=dmp-cu9',
    'www.vodafone.es/tienda/particulares/es/productos/particulares-fibra-tv/?dmp=12626262:cp-acciondmp:cn-dmp:md-CU11b&c_var_11=dmp-cu11b',
    'www.vodafone.es/tienda/particulares/es/productos/particulares-fibra-gratis/?dmp=12626262:cp-acciondmp:cn-dmp:md-CU13&c_var_11=dmp-cu13',
    'www.vodafone.es/tienda/particulares/es/productos/oferta-exclusiva/?dmp=12626262:cp-acciondmp:cn-dmp:md-CU20&c_var_11=dmp-cu20',
    'www.vodafone.es/tienda/particulares/es/clientes/catalogo/ficha/particular/movil/samsung/galaxy-s9-plus-oro/?subtipoContrato=2&idPrecio=997641758',
    'www.vodafone.es/static/microsites/particulares-oferta-exclusiva/index.html?dmp=12626262:cp-acciondmp:cn-dmp:md-CU24&c_var_11=dmp-cu24',
    'www.vodafone.es/tienda/particulares/es/productos/mas-lineas-vacaciones/?dmp=12626262:cp-acciondmp:cn-dmp:md-CU25&c_var_11=dmp-cu25',
    'oferta.vodafone.es/v-vodafone/?dmp=12626262:cp-acciondmp:cn-dmp:md-CU33&c_var_11=dmp-cu33',
    'www.vodafone.es/tienda/particulares/es/productos/particulares-migraciones-fibra-directa/?dmp=12626262:cp-acciondmp:cn-dmp:md-CU34&c_var_11=dmp-cu34',
    'www.vodafone.es/tienda/particulares/es/productos/particulares-migracionesfibra/?dmp=12626262:cp-acciondmp:cn-dmp:md-CU35&c_var_11=dmp-cu35',
    'www.vodafone.es/static/microsites/particulares-descuento-unico/index.html?dmp=12626262:cp-acciondmp:cn-dmp:md-CU38a&c_var_11=dmp-cu38a',
    'www.vodafone.es/static/microsites/particulares-descuento-unico-2/index.html?dmp=12626262:cp-acciondmp:cn-dmp:md-CU38b&c_var_11=dmp-cu38b',
    'www.vodafone.es/tienda/particulares/es/catalogo/ficha/particular/movil/lg/g7-plata/?subtipoContrato=1&idPrecio=997608621&c_evar_11=dmp-cu41',
    'www.vodafone.es/tienda/particulares/es/clientes/catalogo/ficha/particular/movil/sony/xperia-xa2/?subtipoContrato=2&idPrecio=989991472&c_evar_11=dmp-cu42',
    'www.vodafone.es/tienda/particulares/es/clientes/catalogo/ficha/particular/movil/huawei/p-smart/?subtipoContrato=2&idPrecio=989990565&c_evar_11=dmp-cu43',
  ]

  const urlIndexado = urls.map((url, index) => ({
    id: index,
    url: url,
  }))

  const { href } = window.location

  urlIndexado.forEach((elem) => {
    if (href.indexOf(elem.url)) {
      return true
    }
  })

  try {
    const iframeVdf = href
    urlIndexado.forEach((elem) => {
      if (iframeVdf.indexOf(elem.url)) {
        return true
      }
    })
  } catch (error) {}

  return false
}
