export const getUrlParams = function () {
  const url = window.location.href
  const params = {}

  if (url.indexOf("?") > 0) {
    const getString = url.split("?")[1]
    const GET = getString.split("&")

    GET.forEach(param => {
      const [key, value] = param.split("=")
      params[key] = decodeURIComponent(value)
    })
  }

  return params
}
