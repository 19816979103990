export const setCookie = function (c_name, value, exmin, path, domain, secure) {
  let dom
  if (typeof domain === "undefined") {
    dom = "." + /[\w\-]+\.[\w]+$/gi.exec(window.location.hostname)[0]
  } else {
    dom = domain
  }
  const exdate = new Date()
  exdate.setTime(exdate.getTime() + exmin * 60000)
  const c_value =
    value +
    (!exmin ? "" : " expires=" + exdate.toUTCString()) +
    (!path ? "" : " path=" + path) +
    (!dom ? "" : " domain=" + dom) +
    (!secure ? "" : " secure=" + secure)

  document.cookie = c_name + "=" + c_value
}


export const setCookieLocalHost=(nombre, valor, expiracion)=>{
  var fecha = new Date();
  document.cookie = nombre + "=" + valor + ";" +";path=/";
}