import { getQueryVariable } from "../utils"

export const getRingPoolService = function (serviceName = "default") {

  const services = {
    default: "c8d85c6aebf14547c575dfccac639730",
    ko: "74c8588ed41d6de8204123c87b56f963",
  }

  if (getQueryVariable("wm_rp_ko") || sessionStorage.wm_rp_ko) {
    sessionStorage.wm_rp_ko = 1
    serviceName = "ko"
  }

  return services[serviceName]
}
