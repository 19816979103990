export const pixelLog = function (...params) {
  const url_pixel = "https://t.womtp.com/slider/c/log/pixel.png?"
  let url_params = ""
  const pixel_log = document.createElement("img")
  pixel_log.style.display = "none"
  for (var key in params) {
    url_params += key + "=" + params[key] + "&"
  }
  pixel_log.src = url_pixel + url_params
  document.body.appendChild(pixel_log)
}
