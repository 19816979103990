import { addFormField } from "./addFormField"
import { getUrl } from "./getUrl"

export const addField_url = function () {
  const input = document.createElement("input")
  input.type = "hidden"
  input.name = "url"
  input.value = getUrl()

  addFormField(input)
}
