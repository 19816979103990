/**
 * 
 * @param {HTMLElement} field DOM Node element to add in all forms in the page
 */
export const addFormField = function (field) {
  const wgForms = document.querySelectorAll(".wg-form")

  wgForms.forEach( (wgForm) => {
    wgForm.appendChild(field.cloneNode(true))
  })
}
