
/**
 * Solicitud para actualizar el back en Pro cambia el idSite
 * **/
export function alEpst(adformId) {
  globalThis.DAL.Ajax({
    type: "jsonp",
    url: "https://t.womtp.com/epst",
    data: {
      idsite: "5f28300c4f97c65b1c0e82e1974f35f2",
      idvisitor: globalThis.Piwik.getAsyncTracker().getVisitorId(),
      adfid: adformId,
      v: "1",
    },
  }).done(function () {});
  
    
}

/**
 * Funcion que llama al enpoint  para actualizar el back en Pre , cambia el idSite
 * **/
export function alEpstPrueba(adformId) {

  globalThis.DAL.Ajax({
    type: "jsonp",
    url: "https://t.womtp.com/epst",
    data: {
      idsite:"5f28300c4f97c65b1c0e82e1974f35f2",
      idvisitor: globalThis.Piwik.getAsyncTracker().getVisitorId(),
      adfid: adformId,
      v: "1",
    },
  }).done(function () { });
}

/** 
 * Notifica al back D3P y crea el adFormUid en la request
**/
export function getAdformId() {
  let interval;
  
  const isLoadAdform = new Promise((resolve, reject) => {
    let cont = 0;
    interval = setInterval(() => {
      if (globalThis.Adform) {
        clearInterval(interval);
  
        globalThis.DAL.Ajax({
          type: "script",
          url: "https://track.adform.net/Serving/Cookie/?adfaction=getjs;adfcookname=uid",
        })
          .done(() => {
            globalThis.adForm_ready && globalThis.adForm_ready();
            resolve(globalThis.Adform._uid); // Resuelve la promesa con _uid
          })
          .fail(() => {
            globalThis.Adform._uid = 1;
            resolve(globalThis.Adform._uid); // Resuelve la promesa con _uid
          });
      } else {
        cont++;
        if (cont > 6) {
          clearInterval(interval);
          globalThis.Adform = globalThis.Adform || {};
          globalThis.Adform._uid = 2;
          globalThis.adForm_ready && globalThis.adForm_ready();
          resolve(globalThis.Adform._uid); // Resuelve la promesa con _uid
        }
      }
    }, 100);
  });
  
  isLoadAdform
    .then(() => {
      clearInterval(interval);
    })
    .catch(() => {
      clearInterval(interval);
    });
  
  // Devuelve la promesa para que pueda ser utilizada fuera de la función
  return isLoadAdform;
}

/**
 * Funcion segun la url y el hostname devuelve el cliente para setear luego el tagVisit
 * @returns {string} client 
 * **/
export const getClient = function () {
  const { hostname,href } = window.location

  const customers = {
    ono: ['www.ono.es', '62.42.232.238', '172.21.4.28', '62.42.232.239'],
    yu: ['www.vodafoneyu.es', 'www.yu.vodafone.es'],
    vodafone_pruebas: ['oferta.vodafone.es'],
  }

  for (const client in customers) {
    if (
      customers[client].includes(hostname) ||
      (client === 'yu' && globalThis.D3P && globalThis.D3P.v_var_4 === 'yu')
    ) {
      return client
    }
    if( customers[client].includes(hostname) && href.includes("vodafone-test-multi") ){
        return client
    }
  }

  return 'vodafone'
}

