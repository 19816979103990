import { addFormField } from "./addFormField"

export const addField_AdformID = function () {
  if (!(globalThis.Adform && globalThis.Adform._uid)) {
    return false
  }

  const input = document.createElement("input")
  input.type = "hidden"
  input.name = "adFormID"
  input.value = globalThis.Adform._uid

  addFormField(input)
}
