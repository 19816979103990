/*
    |---------------------------------------------------------------------------------------------------------
    | Devuelve la url hasta los parámetros ":cp-"(si la url contiene "/onsite/" o "/ficticio/") o "?" o "%3F"
    | nunca se dan los dos casos a la vez
    |---------------------------------------------------------------------------------------------------------
    */
export const selectUrl = function () {
  const url = window.location.href
  const url1 = url.split('?')
  const url2 = url.split('%3F')
  const url3 = url.split(':cp-')

  if (url1.length > 1) {
    return url1[0]
  } else if (url2.length > 1) {
    return url2[0]
  } else {
    return url
  }

  if (url.indexOf('/onsite/') || url.indexOf('/ficticio/')) {
    if (url3.length > 1) {
      return url3[0]
    }
  }
  return window.location.href
}
