import { DFI } from '../../tienda'
import { getLeadId } from '../utils'

/**
 * Función que llama al enpoint que actualiza las etiquetas de visita
 * @param {object} data - data de etiquetas de visita
 **/
const updateVisitNew = async (data) => {
  data = data ? data : ''
  const idVisitor = await getLeadId()

  globalThis.DAL.Ajax({
    type: 'jsonp',
    url: 'https://ws.walmeric.com/provision/visitsLabel/updateVisitLabelByVisitorId.html',
    data: {
      format: 'jsonp',
      idTag: DFI.global.idTagDelioClient,
      idVisitor: idVisitor,
      data: JSON.stringify(data),
    },
  })
}

/**
 * Funcion que setea las etiquetas de conversion y las womtp.cvar [4,6,8,10] segun una serie de url o el hostname
 **/

export const setConversionVars = function () {
  const { pathname, hostname } = window.location
  const pathParts = pathname.split('/')
  const pageName = globalThis.utag_data ? globalThis.utag_data.gb_pagename : ''
  const defaultSite = 'landing'

  const siteMappings = {
    landing: () => pageName,
    static: () => pageName,
    tienda_cobertura: () => undefined,
    tienda: () => undefined,
    web_publica: () => undefined,
    oferta: () => undefined,
    smartphonesvodafone: () => undefined,
    eshop: () => undefined,
    'Vodafone YU': () => undefined,
  }

  const detectedSitePro = {
    site: 'landing',
    pageName: siteMappings[defaultSite]() || pageName,
  }

  const pathMappings = [
    { hostname: 'www.vodafone.es', path: 'static', site: 'static' },
    {
      hostname: 'www.vodafone.es',
      path: 'one/cobertura',
      site: 'tienda_cobertura',
    },
    {
      hostname: 'www.vodafone.es',
      path: 'one/cobertura-fibra',
      site: 'tienda_cobertura',
    },
    { hostname: 'www.vodafone.es', path: 'tienda', site: 'tienda' },
    {
      hostname: 'www.vodafone.es',
      path: 'productos-y-servicios',
      site: 'web_publica',
    },
  ]

  pathMappings.forEach((mapping) => {
    if (hostname === mapping.hostname && pathParts[1] === mapping.path) {
      detectedSitePro.site =
        siteMappings[mapping.site]() || detectedSitePro.site
    }
  })

  const d3pSite = globalThis.D3P && globalThis.D3P.v_var_4
  const d3pPageName = globalThis.D3P && globalThis.D3P.v_var_6

  detectedSitePro.site = siteMappings[d3pSite]?.() || detectedSitePro.site
  detectedSitePro.pageName =
    siteMappings[d3pPageName]?.() || detectedSitePro.pageName

  if (
    hostname === 'oferta.vodafone.es' &&
    pathname.includes('descuento-especial') &&
    globalThis.DELIO.dinamicLabels &&
    sessionStorage.wm_isp
  ) {
    detectedSitePro.site = siteMappings['oferta']() || detectedSitePro.site
  } else if (hostname === 'oferta.vodafone.es') {
    detectedSitePro.site = siteMappings['oferta']() || detectedSitePro.site
  } else if (hostname === 'smartphonesvodafone.com') {
    detectedSitePro.site =
      siteMappings['smartphonesvodafone']() || detectedSitePro.site
  } else if (hostname === 'shop.v.vodafone.com') {
    detectedSitePro.site = siteMappings['eshop']() || detectedSitePro.site
  } else if (
    hostname === 'www.vodafoneyu.es' ||
    hostname === 'www.yu.vodafone.es'
  ) {
    detectedSitePro.site = siteMappings['Vodafone YU']() || detectedSitePro.site
  }

  globalThis.womtp = globalThis.womtp || {}
  globalThis.womtp.c_vars = globalThis.womtp.c_vars || []

  globalThis.womtp.c_vars[1] = globalThis.c_var_1 =
    (globalThis.D3P && globalThis.D3P.c_var_1) ||
    globalThis.c_var_1 ||
    globalThis.v_var_1
  globalThis.womtp.c_vars[4] = globalThis.c_var_4 = detectedSitePro.site
  globalThis.womtp.c_vars[6] = globalThis.c_var_6 = detectedSitePro.pageName
  globalThis.womtp.c_vars[8] = globalThis.c_var_8 = DFI.getPlacement() ?? ''
  globalThis.womtp.c_vars[10] = globalThis.location.href.split('?')[0]
}

/**
 * Funcion que setea las etiquetas de visita [1,4,5,8,9,15,6] segun la url o hostname
 * Llama a la funcion updateVisitNew para hacer el fecth y le pasa el Obj
 * **/

export const setVisitVars = async function () {
  const { pathname, hostname } = window.location
  const pathParts = pathname.split('/')
  const detectedSitePro = {
    site: 'landing',
    pageName: globalThis.utag_data ? globalThis.utag_data.gb_pagename : '',
  }
  /*Resuelve la promise dado un tiempo */
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

  const pathMappings = [
    {
      hostname: 'www.vodafone.es',
      paths: {
        static: 'static',
        'tienda-particulares-es-one-cobertura': 'tienda_cobertura',
        'tienda-particulares-es-one-cobertura-fibra': 'tienda_cobertura',
        tienda: 'tienda',
        'productos-y-servicios': 'web_publica',
      },
    },
  ]

  pathMappings.forEach((mapping) => {
    if (hostname === mapping.hostname) {
      for (const [path, site] of Object.entries(mapping.paths)) {
        if (pathParts.includes(path)) {
          detectedSitePro.site = site
          break
        }
      }
    }
  })

  const d3pSite = globalThis.D3P && globalThis.D3P.v_var_4
  const d3pPageName = globalThis.D3P && globalThis.D3P.v_var_6

  detectedSitePro.site = d3pSite || detectedSitePro.site
  detectedSitePro.pageName = d3pPageName || detectedSitePro.pageName

  globalThis.womtp = globalThis.womtp || {}
  globalThis.womtp.v_vars = globalThis.womtp.v_vars || []

  // Define a mapping of LP paths to v_var_0 values
  const lpCarteraMapping = {
    '/tv-adsl/': 'cartera',
    '/tv-fibra/': 'cartera',
    '/tv-fibra-one/': 'cartera',
  }

  // Check if the current path is in the LP mapping
  const lpCarteraValue = lpCarteraMapping[pathname]

  if (hostname === 'oferta.vodafone.es' && lpCarteraValue) {
    globalThis.v_var_1 = lpCarteraValue
  }

  globalThis.womtp.v_vars[1] = globalThis.v_var_1 =
    (globalThis.D3P && globalThis.D3P.v_var_1) || globalThis.v_var_1
  globalThis.womtp.v_vars[4] = globalThis.v_var_4 = detectedSitePro.site
  globalThis.womtp.v_vars[5] = globalThis.v_var_5 =
    DFI.getCookie('tid_walmeric')
 /*Las etiquetas 8 y 9 solo tienen valor si se han aceptado las cookies. Mirar funcion loadMcvid()*/
  globalThis.womtp.v_vars[8] = globalThis.v_var_8

  globalThis.womtp.v_vars[9] = globalThis.v_var_9

  globalThis.womtp.v_vars[10] = globalThis.v_var_10 =
    globalThis.location.href.split('?')[0]

  globalThis.womtp.v_vars[15] = globalThis.v_var_15 = DFI.getDevice()

  globalThis.womtp.v_vars[6] = globalThis.v_var_6 = globalThis.utag_data
    ? globalThis.utag_data.gb_pagename
    : 'No se esta recogiendo bien el utag_data'

  /* Etiquetas de visita [1,4,5,8,9,10,15,6] que se actulizaran llamando al servicio updateLabelVisit , cada vez que se aterrice en una 
  landing page . 
  */

  const updateVisitTo = [
    {
      id: 1,
      value: globalThis.v_var_1,
    },
    {
      id: 4,
      value: globalThis.v_var_4,
    },
    {
      id: 5,
      value: globalThis.v_var_1,
    },
    {
      id: 6,
      value: globalThis.v_var_6,
    },
    {
      id: 8,
      value: globalThis.v_var_8,
    },
    {
      id: 9,
      value: globalThis.v_var_9,
    },
    {
      id: 10,
      value: globalThis.v_var_10,
    },
    {
      id: 15,
      value: globalThis.v_var_15,
    },
  ]

  updateVisitNew(updateVisitTo)
}
