import { DFI } from "../../tienda"
/**
 * Funcion que configura un objeto con los mensajes de error que queremos enviar a Delio y  que provienen de la funcion errorWalmericPersonalized
 * 
 * **/

export const objectErrorWm=(errorWalmeric)=>{

    const ObjectErrorWalmeric={
        "trazaError":errorWalmeric.mensaje,
        "tipoError":errorWalmeric.tipoError,
        "ficheroError":errorWalmeric.fichero,
        "lineaError":errorWalmeric.lineaError,
        "fechaError":errorWalmeric.fechaError
    }


    return ObjectErrorWalmeric
}



