export const getUrlSubParams = function (params) {
  try {
    if (params.indexOf(":") > 0) {
      const splitParams = params.split(":").slice(1)
      const finalParams = {}

      splitParams.forEach(param => {
        const [key, ...values] = param.split("-")
        const value = values.join("-")
        finalParams[key] = value
      })

      return finalParams
    }
  } catch (error) { }
}
