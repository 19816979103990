/**
 * Usamos el fakeDatalayer cuando es solamente pre : es decir cuando el host es localHost:9006
 */
export const fakeDatalayer=[
    {
        "0": "js",
        "1": "2024-04-15T13:17:49.815Z",
        "gtm.uniqueEventId": 1
    },
    {
        "0": "set",
        "1": "url_passthrough",
        "2": false
    },
    {
        "0": "set",
        "1": "ads_data_redaction",
        "2": false
    },
    {
        "0": "consent",
        "1": "default",
        "2": {
            "ad_storage": "granted",
            "analytics_storage": "granted",
            "wait_for_update": 0,
            "ad_user_data": "granted",
            "ad_personalization": "granted"
        }
    },
    {
        "0": "event",
        "1": "page_view",
        "2": {
            "send_to": "AW-987103523",
            "value": "",
            "funnel_step": "",
            "items": [
                {
                    "id": "",
                    "location_id": "",
                    "google_business_vertical": "custom"
                }
            ]
        }
    },
    {
        "0": "config",
        "1": "AW-987103523",
        "2": {
            "user_id": "168ad4d5bda3af3e760911e4b886dd2b471e52d848c6e7081b1babe718ef5107"
        }
    },
    {
        "0": "event",
        "1": "page_view",
        "2": {
            "send_to": [
                "AW-987103523"
            ]
        }
    },
    {
        "0": "event",
        "1": "conversion",
        "2": {
            "allow_custom_scripts": true,
            "u2": "https://www.vodafone.es/c/particulares/es/productos-y-servicios/movil/contrato/tarifas-contrato/test-walmeric/",
            "u3": "",
            "u4": "",
            "u5": "vfes:particulares:productos y servicios:movil:contrato:tarifas contrato:test walmeric",
            "send_to": "DC-9476746/invmedia/vdf_c0+standard"
        }
    },
    {
        "0": "event",
        "1": "conversion",
        "2": {
            "allow_custom_scripts": true,
            "u2": "https://www.vodafone.es/c/particulares/es/productos-y-servicios/movil/contrato/tarifas-contrato/test-walmeric/",
            "u5": "vfes:particulares:productos y servicios:movil:contrato:tarifas contrato:test walmeric",
            "send_to": "DC-9476746/invmedia/tol_b0+unique"
        }
    },
    {
        "0": "config",
        "1": "DC-9476746",
        "2": {
            "user_id": "168ad4d5bda3af3e760911e4b886dd2b471e52d848c6e7081b1babe718ef5107"
        }
    },
    {
        "event": "gtm.dom",
        "gtm.uniqueEventId": 11
    },
    {
        "OnetrustActiveGroups": ",1,2,3,4,"
    },
    {
        "OptanonActiveGroups": ",1,2,3,4,"
    },
    {
        "event": "OneTrustGroupsUpdated",
        "OnetrustActiveGroups": ",1,2,3,4,",
        "gtm.uniqueEventId": 12
    },
    {
        "event": "OneTrustLoaded",
        "OnetrustActiveGroups": ",1,2,3,4,",
        "gtm.uniqueEventId": 13
    },
    {
        "event": "OptanonLoaded",
        "OptanonActiveGroups": ",1,2,3,4,",
        "gtm.uniqueEventId": 14
    },
    {
        "0": "js",
        "1": "2024-04-15T13:17:52.926Z"
    },
    {
        "0": "config",
        "1": "AW-987103523"
    },
    {
        "event": "gtm.load",
        "gtm.uniqueEventId": 17
    }
]



