/*Funcion que comprueba que este el Visitor : Importante es normal que en Preproduccion te salte este error
esto es debido a que en local VF no esta metiendo el tag de Adobe ni el el de mcvid */
export function waitForVisitorObject() {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (globalThis.visitor) {
          clearInterval(interval);
          resolve(true);
        }else{
          reject(new Error('Visitor no existe'));
        }
      }, 800);
    });
  }
