import { DFI } from '../../tienda'

import { getLeadId, getUtagAnalitica, loadScript } from '../utils'


/**
 * Función que devuelve un objeto con todas las etiquetas Declarativas y de Conversion del ringpool
 * @param {String} adFormID - adFormID
 * 
 * **/
export const getDataLeadRingpool = async( adFormID = "", cookieAnaliticaInformada = false) => {


  let EDeclaratives = {
    url                     :         DFI.getUrl(),
    Isp                     :         DFI.getISP(),
    entryurl                :         globalThis.utag_data ? globalThis.utag_data.gb_entry_url : "Error: no existe utag_data gb_entry_url",
    personalizacion         :         globalThis.utag_data ? globalThis.utag_data.personalizacion_ws : "Error: no existe utag_data personalizacion_ws",
    gb_channel              :         globalThis.utag_data ? globalThis.utag_data.gb_channel : "Error: no existe utag_data gb_channel",
    gb_asset_name           :         globalThis.utag_data ? globalThis.utag_data.gb_asset_name : "Error: no existe utag_data gb_asset_name",
    adFormID                :         adFormID ?? '',
    pagename                :         globalThis.utag_data ? globalThis.utag_data.gb_pagename : "Error: no existe utag_data gb_pagename",
    TID                     :         DFI.getCookie('tid_walmeric') ?? '',
    URL_sin_parametro       :         DFI.selectUrl() ?? '',
    DMP                     :         DFI.isDMPCartera() ? 'cartera' : '',
    cookieAnalitica         :         getUtagAnalitica(cookieAnaliticaInformada),
    location_cta            :         globalThis.location_cta ?? 'cta ringpool defecto',
    _ttp                    :         DFI.getCookie('_ttp') ?? "",
    ttclid                  :         DFI.getCookie('ttclid') ?? "",
    ad_storage              :         globalThis.consentValues ? globalThis.consentValues.ad_storage : 'denied',
    analytics_storage       :         globalThis.consentValues ? globalThis.consentValues.analytics_storage : 'denied',
    ad_personalization      :         globalThis.consentValues ? globalThis.consentValues.ad_personalization : 'denied',
    ad_user_data            :         globalThis.consentValues ? globalThis.consentValues.ad_user_data : 'denied',
    refactor                :         'S',
    element_cta             :         globalThis.element_cta ?? 'cta ringpool element defecto',
    
  };
    
    let EConversion = [
      { id: '4', value: globalThis.womtp.c_vars[4] },
      { id: '8', value: globalThis.womtp.c_vars[8] },
      { id: '6', value: globalThis.womtp.c_vars[6] },
      { id: '10', value: globalThis.womtp.c_vars[10] },
    ];
    
  const handleObj = {
    get: (target, prop) => {
      return target[prop];
    },
    set: (target, prop, value) => {
      try {
        const arrayPropiedades = Object.keys(target);
        if (!arrayPropiedades.includes(prop)) {
          throw new Error("Propiedad no existe en obj Datalead");
        }
        target[prop] = value;
      } catch (error) {
        console.error(error.message);
      }
  
      try {
        const newValue = value;
        if (!newValue) {
          throw new Error("Esta intentado insertar un valor vacio en la propiedad " + prop);
        }
        target[prop] = value;
      } catch (error) {
        console.error(error);
      }
    },
  };
    
  // @ts-ignore
  const dataLeadCallMeDeclarativas = new Proxy(EDeclaratives,handleObj);
  
  const dataLead = {
    EDeclaratives: dataLeadCallMeDeclarativas,
    EConversion: EConversion,
  };
  
  return new Promise((resolve, reject) => {
    resolve(dataLead);
  });
}
/**
 * Función que llama al enpoint del envio de etiquetas del ringpool mediante fetch 
 * @param {String} idLeadParam - idLead 
 * **/
const loadConversionLead = async (idLeadParam="") => {
  const idLeadGet = globalThis.Piwik.getAsyncTracker().getVisitorId()
  const idLead = idLeadParam!="" ? idLeadParam : idLeadGet
  const dataLeadLabels = await JSON.stringify(getDataLeadRingpool())

  const promiseConversion = globalThis.DAL.Promise()

  const endPointSendLabel =
    DFI.global.sendLabelRingpool +
    'idTag=' +
    DFI.global.idTagDelioClient +
    '&idvisitor=' +
    idLead +
    '&labels=' +
    dataLeadLabels +
    '&idServ=' +
    DFI.getRingPoolService()

  globalThis.DAL.Ajax({
    type: 'jsonp',
    url: endPointSendLabel,
  })
    .done(() => {
      promiseConversion.resolve()
    })
    .fail(() => {
      promiseConversion.reject()
    })
}
/**
 * Función que dado el idLead inserta el script de conversion segun el tipo de cliente 
 * @param {Object} idleadParams - idLead
 * 
 * **/
const loadConversion = async (idleadParams, fn) => {
  const idLead = await getLeadId()
  console.log({ idLead })
  const idlead = idleadParams || idLead
  const nameClient = DFI.getClient()
  const tagConversion = DFI.global.tagConversion[nameClient]

  const loadScriptPromise = loadScript(tagConversion + idlead)

  if (fn) {
    loadScriptPromise.then(fn)
  }

  return loadScriptPromise
}

/**
 * Funcion que devuelve un numero inbound por defecto dado el hostname 
 * @returns {string} phone
 * **/
const getInboundPhoneRP = () => {
  /** Teléfono inbound por defecto **/
  const defaultPhone = '900920052'

  const locationMapInbound = {
    '/c/tienda-online/particulares/': '900920155',
    '/c/particulares/es/productos-y-servicios/movil/catalogo-clientes/':
      '900920155',
    'oferta.vodafone.es': '900920230',
  }

  const pathname = location.pathname
  const hostname = location.hostname

  const phone =
    locationMapInbound[pathname] || locationMapInbound[hostname] || defaultPhone

  
  return phone
}
/**
 * Funcion callback  que se ejecuta cuando se pide un numero del ringpool y pinta la capa 
 * @param {object} resp - Respuesta 
 *  Mirar documentacion en : {@link https://walmeric.atlassian.net/wiki/spaces/WFRONT/pages/41779270/DRP+-+Delio+RingPool}
 * 
 */
const reqPhone = (resp) => {
  globalThis.DRP.paintCapa(document.querySelectorAll('.drp-phone'))
  console.log({ resp })
}

/**
 * Funcion callback que se ejecuta cuando una llamada es efectuada por un número ringpool
 * @param {object} resp - Respuesta 
 *  Mirar documentacion en : {@link https://walmeric.atlassian.net/wiki/spaces/WFRONT/pages/41779270/DRP+-+Delio+RingPool}
 * **/

// const called = async (resp) => {
//   //  Carga de conversion
//   loadConversion(
//     {
//       key: 'uid',
//       id: resp.uid,
//     },
    
//     //gracias llama a window.gracias(mirar con ojo esa funcion) //
//   )

//   // Se emite la notificacion
//   DFI.notifyD3P({
//     type: 'ringpool',
//     result: 'OK',
//   })

//   /**Actualiza etiquetas DEC y Conversion**/
//   globalThis.DRP.loadLabels(await getDataLeadRingpool())
  
//   /**Load Conversion : Declaratives and conversion**/
//   loadConversionLead()
//   /**EntryURL HACERLO CON JSON P LIBRERIA FECTH
//    * Estos valores no estan en el utag data
//    *entryurl: utag_data.entry_url,
//     personalizacion: utag_data.personalizacion_ws,
//    * **/
//   globalThis.DAL.Ajax({
//     type: 'GET',
//     url: 'https://ws.walmeric.com/provision/wsclient/delioClientUid_put.html',
//     dataType: 'jsonp',
//     data: {
//       idTag: DFI.global.idTagDelioClient,
//       uid: resp.uid,
//       data: JSON.stringify({
//         EDeclaratives: {
//           entryurl: '',
//           personalizacion: '',
//         },
//       }),
//     },
//   })
// }


