import { addFormField } from "./addFormField";

function createInput({ type, name, value, className }) {
  const input = document.createElement("input");
  input.type = type;
  input.value = value;
  if(name) input.name = name;
  if(className) input.className = className;
  addFormField(input);
}

export const addDeclarativeField = function (name, value) {
  const input = document.createElement("input");
  input.type = "hidden";
  input.value = value;
  input.name = name;
  return input
}


export const addConversionField = function (name,value,className) {
  const input = document.createElement("input");
  input.type = "hidden";
  input.value = value;
  input.name = name;
  input.className=className
  return input
}
